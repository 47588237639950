<!-- 
  todo:
  automatically pull up new boxes for random +1s
  automatically create box with linked person
-->

<script>
  import { loading } from "../components/store.js";
  import Loader from "../components/LoadingSpinner.svelte";
  import Meta from "../components/Meta.svelte"
  import TopNav from "../components/Top-Nav.svelte"
  import Footer from "../components/Footer.svelte"
  import RSVP from "../components/RSVP.svelte"

  $: sizeClass = ""
  $: innerWidth = 0;
  $: {
      if (innerWidth >= 600) {
        sizeClass = ""
      }
      else {
        sizeClass = ["col-full", "col-two-thirds", "col-half", "col-third", "col-fourth"]
      };
  };
</script>

<Meta title='RSVP'/>
<TopNav />
{#if $loading} 
  <Loader />
{/if}

<svelte:window bind:innerWidth />
{#if $loading} 
<Loader />
{/if}

<RSVP />

<Footer />

<style>

</style>