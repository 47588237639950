<script>
    import { functions } from "../functions"
    export let sizeClass = "";
    export let containerId = "";
    export let label = "";
    export let name = "";
    export let type = "";
    export let inputId = "";
    export let required = "required";
    export let readonly = "";
    export let value = "";
    export let options = [];
    export let description = "";
    import { onMount } from "svelte";
    onMount(() => {
      functions.mountFormActions()
    })
</script>

<div class="{sizeClass}">
    <div class="form-group bmd-form-group is-focus" id="{containerId}">
      <div class="bmd-label-floating"> {label} </div>
      <select class="form-control" name={name}
        on:focus={functions.underline} 
        on:blur={functions.deunderline} 
        on:change={functions.saveInput}
      type="{type}" id="{inputId}" {required} {readonly} bind:value={value}>
      {#each options as option}
        <option value={option[0]}>{option[1]}</option>
      {/each}
      </select>
    </div>
    <p>{description}</p>
</div>

<style>
  p {
    color: var(--description-text);
    font-size: 12px;
    margin-top: -14px;
  }
</style>