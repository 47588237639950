<script>
  import Meta from "../components/Meta.svelte"
  import TopNav from "../components/Top-Nav.svelte"
  import Dashboard from "../components/Dashboard.svelte"
  import Footer from "../components/Footer.svelte"

  $: sizeClass = ""
  $: innerWidth = 0;
  $: {
      if (innerWidth >= 600) {
        sizeClass = ""
      }
      else {
        sizeClass = ["col-full", "col-two-thirds", "col-half", "col-third", "col-fourth"]
      };
  };

</script>

<Meta title='Dashboard'/>
<TopNav />

<svelte:window bind:innerWidth />

<h1>
  Dashboard
</h1>
<br>
<br>
<Dashboard />

<Footer />

<style>
  h1 {
    padding-top: 0.5em;
  }
  .message {
    margin: 20px 0px 24px;
  }
</style>