import { writable } from 'svelte/store';

//get the saved value function - return the value of "v" from localStorage. 
function getSavedValue(v){
    if (!localStorage.getItem(v)) {
        return "";
    };
    return localStorage.getItem(v);
};

function getSavedValueAsObject(v){
    const storage = Object.keys(localStorage);
    const result = {};
    for (let i = 0; i < storage.length; i++) {
        const el = storage[i];
        if (el.match(v) !== null) {
            result[el] = localStorage.getItem(el)
        }
    }
    return result;
};

export const loading = writable(false);
export const theme = writable(false);
export const ianMode = writable("false");
export let timer = writable("")
export const checkboxes = writable({});

export const rsvp = writable({
    first_name: [],
    last_name: [],
    attending: [],
    food: [],
    food_details: [],
    songs: '',
});

export const plus = writable({
    name: [],
    attending: [],
    food: [],
    food_details: [],
});

export const invite = writable({
    title: getSavedValueAsObject('title'),
    name: getSavedValueAsObject('name'),
    street: getSavedValue('street'),
    aptFloor: getSavedValue('aptFloor'),
    city: getSavedValue('city'),
    stateRegion: getSavedValue('stateRegion'),
    zip: getSavedValue('zip'),
    email: getSavedValue('email'),
    phone: getSavedValue('phone'),
    guests: getSavedValue('guests')
});

export const login = writable({
    email: getSavedValue('email'),
    password: "",
});

export const specialUsername = writable(null);
export const search = writable(null);
export const selection = writable(null);
export const inviteCount = writable(0);
export const rsvpCount = writable(0);