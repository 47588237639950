<script>
  import { selection } from "./store.js";
  import { functions } from "../functions";
  import InputSelect from "./Input-Select.svelte";

  $: sizeClass = "";
  $: innerWidth = 0;
  $: {
    if (innerWidth >= 600) {
      sizeClass = "";
    } else {
      sizeClass = [
        "col-full",
        "col-two-thirds",
        "col-half",
        "col-third",
        "col-fourth",
      ];
    }
  }
</script>

{#await functions.getRSVPsWithPluses(undefined, $selection) then RSVPs}
  <div class="stats">
    Attending: {RSVPs.filter((response) => response.attending === 1).length}
  </div>
  <div class="stats">
    Not attending: {RSVPs.filter((response) => response.attending === 0).length}
  </div>
  <InputSelect
    sizeClass={sizeClass === "" ? "col-fourth" : sizeClass[2]}
    containerId="selectionContainer"
    label="Sort list"
    name="selection"
    inputId="selection"
    required=""
    type="submit"
    options={[
      ["", "Sort by..."],
      ["first_name", "First name"],
      ["last_name", "Last name"],
      ["attending", "Attending"],
      ["food", "Food preferences"],
      ["songs", "Song choices"],
      ["datetime", "Date submitted"],
    ]}
    bind:value={$selection}
  />
  <div>
    <table class="styled-table">
      <thead>
        <tr>
          {#each Object.keys(RSVPs[0]) as key}
            <th>{key}</th>
          {/each}
        </tr>
      </thead>
      <tbody>
        {#each RSVPs.reverse() as RSVP}
          <tr>
            {#each Object.keys(RSVP) as key}
              {#if key === "attending"}
                <td>{RSVP[key] === 1 ? "Yes" : "No"}</td>
              {:else}
                <td>{RSVP[key] === null ? "" : RSVP[key]}</td>
              {/if}
            {/each}
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
  <div class="not-responded">
    <h3>Unresponsive</h3>
    <br />
    {#await functions.getInvitees() then invitees}
      <div style="display: none">
        {(invitees = invitees.filter(
          (guest) =>
            !RSVPs.map((guest) =>
              guest.first_name.concat(guest.last_name)
            ).includes(guest.first_name.concat(guest.last_name))
        ))}
      </div>
      {#each invitees as notResponded}
        <div>
          {notResponded.first_name.concat(..." ", notResponded.last_name)}
        </div>
      {/each}
    {/await}
  </div>
{/await}

<style>
  .styled-table th,
  .styled-table td {
    padding: 12px 15px;
  }
  .styled-table thead tr {
    background-color: var(--button-border);
    color: #ffffff;
    text-align: left;
  }
  .styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    border: 1px solid var(--button-border);
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 357px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }

  .styled-table tbody tr {
    border-bottom: 1px solid var(--button-border);
  }

  .styled-table tbody tr:nth-of-type(even) {
    background-color: var(--background-secondary);
  }

  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50% !important;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }

    thead {
      visibility: hidden;
    }

    /*
	Label the data
	*/
    td:nth-of-type(1):before {
      content: "Id";
    }
    td:nth-of-type(2):before {
      content: "First Name";
    }
    td:nth-of-type(3):before {
      content: "Last Name";
    }
    td:nth-of-type(4):before {
      content: "Attending";
    }
    td:nth-of-type(5):before {
      content: "Food preference";
    }
    td:nth-of-type(6):before {
      content: "Food detail";
    }
    td:nth-of-type(7):before {
      content: "Song choices";
    }
    td:nth-of-type(8):before {
      content: "Date submitted";
    }
  }
</style>
