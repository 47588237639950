<script>
  import router from "page";
  import { onMount } from "svelte";
  import { theme, ianMode } from "./components/store.js";
  import Home from "./routes/Home.svelte";
  import RSVP from "./routes/RSVP.svelte";
  import RSVPReceived from "./routes/RSVP-Received.svelte";
  import Details from "./routes/Details.svelte";
  import Dashboard from "./routes/Dashboard.svelte";
  import Photos from "./routes/Photos.svelte";
  import Blank from "./routes/Blank.svelte";

  let page;
  let props;
  $theme = "none";
  $ianMode = "false";

  router("/", () => (page = Home));
  router("/rsvp", () => (page = RSVP));
  router("/rsvp-received", () => (page = RSVPReceived));
  router("/photos", () => (page = Photos));
  router("/details", () => (page = Details));
  router("/dashboard", () => (page = Dashboard));
  router("/*", () => (page = Home));

  router.start();

  onMount(async () => {
    props = { theme: $theme, ianMode: $ianMode };
  });
</script>

<main>
  <svelte:component this={page} {...props} />
</main>
