<script>
  import Meta from "../components/Meta.svelte";
  import TopNav from "../components/Top-Nav.svelte";
  import Footer from "../components/Footer.svelte";
  import { onMount } from "svelte";
  import { ianMode } from "../components/store";

  $: sizeClass = "";
  $: innerWidth = 0;
  $: {
    if (innerWidth >= 600) {
      sizeClass = "";
    } else {
      sizeClass = [
        "col-full",
        "col-two-thirds",
        "col-half",
        "col-third",
        "col-fourth",
      ];
    }
  }
  onMount(async() => {
    if (localStorage.getItem("ianMode") === null) {
      localStorage.setItem("ianMode", "false");
    }
    localStorage.getItem("ianMode") === "false"
      ? ($ianMode = "true")
      : ($ianMode = "false");
    localStorage.getItem("ianMode") === "false"
      ? document.documentElement.setAttribute("data-theme", "none")
      : document.documentElement.setAttribute("data-theme", "dark");
    localStorage.getItem("ianMode") === "true"
      ? (document.getElementById("toggle").checked = true)
      : (document.getElementById("toggle").checked = false);
  })
</script>

<Meta title="RSVP" />
<TopNav />

<svelte:window bind:innerWidth />
<div class="received-container">
  <h1>RSVP</h1>
  <div class="message">
    Thank you for submitting your RSVP, {localStorage.getItem(
      "arthurbuchananrsvp"
    )}!
  </div>
  <div id="frame-container">
    <iframe
      title="surprise!"
      id="frame"
      src="https://www.youtube.com/embed/CGILwrlzSLg?si=CPgoJYmTe7-d4sal?autoplay=1"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
  </div>
</div>

<Footer />

<style>
  .received-container {
    margin: 1rem;
  }
  #frame-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  }
  #frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  iframe {
    display: flex;
  }
  h1 {
    padding-top: 0.5em;
  }
  .message {
    margin: 20px 0px 24px;
  }
</style>
