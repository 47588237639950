<script>
  import { loading, rsvp, search, plus } from "./store.js";
  import Loader from "./LoadingSpinner.svelte";
  import { onMount } from "svelte";
  import RSVPForm from "./RSVP-Form.svelte";
  import { functions } from "../functions";
  import { ianMode } from "../components/store";

  let invitees;
  let searched = false;
  let hasPlusOne = false;
  let formLoaded = false;

  onMount(async () => {
    if (localStorage.getItem("ianMode") === null) {
      localStorage.setItem("ianMode", "false");
    }
    localStorage.getItem("ianMode") === "false"
      ? ($ianMode = "true")
      : ($ianMode = "false");
    localStorage.getItem("ianMode") === "false"
      ? document.documentElement.setAttribute("data-theme", "none")
      : document.documentElement.setAttribute("data-theme", "dark");
    localStorage.getItem("ianMode") === "true"
      ? (document.getElementById("toggle").checked = true)
      : (document.getElementById("toggle").checked = false);
    functions.mountFormActions();
    invitees = await functions.getInvitees();
    for (let i = 0; i < invitees.length; i++) {
      const person = invitees[i];
    }
  });

  $: filteredData = $search
    ? invitees.filter((invitee) => {
        let checkNickname =
          invitee.nickname !== undefined && invitee.nickname !== null
            ? invitee.nickname.toLowerCase()
            : "";
        return (
          invitee.last_name.toLowerCase() === $search.toLowerCase() ||
          checkNickname === $search.toLowerCase()
        );
      })
    : [];

  const goBack = async () => {
    $rsvp.last_name[0] = "";
    formLoaded = false;
    await new Promise((resolve) => setTimeout(resolve, 1));
    document.getElementById("search").focus();
  };

  const handleEnter = (key) => {
    if (document.getElementById("search").value === "") {
      return;
    }
    if (key.code === "Enter") {
      searched = true;
      document.getElementById("search").blur();
    }
    if (key.code === "Tab") {
      searched = true;
      document.getElementById("search").blur();
    }
  };

  $: sizeClass = "";
  $: innerWidth = 0;
  $: {
    if (innerWidth >= 600) {
      sizeClass = "";
    } else {
      sizeClass = [
        "col-full",
        "col-two-thirds",
        "col-half",
        "col-third",
        "col-fourth",
      ];
    }
  }
</script>

<svelte:window bind:innerWidth />
{#if $loading}
  <Loader />
{/if}
<div class="container">
  {#if $rsvp.last_name[0] === undefined || $rsvp.last_name[0] === ""}
    <h1>RSVP</h1>
    <div class="message">Enter your last name to fill in your RSVP.</div>
    {#if innerWidth >= 600}
      <div class="align-left">
        <input
          id="search"
          type="search"
          on:focus={() => {
            (searched = false), ($search = "");
          }}
          on:keyup={handleEnter}
          bind:value={$search}
          class="ms-auto w-auto"
          placeholder="Last name..."
        />
        <button
          on:click={() => {
            searched = true;
          }}
          class="btn btn-primary search-button">Search</button
        >
      </div>
    {:else}
      <div class="right-box">
        <input
          id="search"
          type="search"
          on:focus={() => {
            (searched = false), ($search = "");
          }}
          on:keyup={handleEnter}
          bind:value={$search}
          class="ms-auto w-auto"
          placeholder="Last name..."
        />
        <button
          on:click={() => {
            searched = true;
          }}
          class="btn btn-primary search-button">Search</button
        >
      </div>
    {/if}

    {#if filteredData !== undefined && searched}
      <div class="message">Click your name to fill out your RSVP.</div>
      {#each filteredData as invitee}
        <ul>
          <li>
            <a
              href="#"
              on:click={() => {
                (formLoaded = true),
                  ($rsvp.first_name[0] = invitee.first_name),
                  ($rsvp.last_name[0] = invitee.last_name),
                  (hasPlusOne = invitee.plus),
                  localStorage.setItem(
                    "arthurbuchananrsvp",
                    invitee.first_name
                  );
              }}
            >
              {invitee.first_name + " " + invitee.last_name}
            </a>
          </li>
        </ul>
      {/each}
    {/if}
    {#if filteredData !== undefined && filteredData.length === 0 && searched}
      <div class="message plain">
        Oops, I'm not seeing you here 📋🧐 is your name spelled correctly?
      </div>
    {/if}
  {/if}

  {#if formLoaded}
    <div class="align-space-between">
      <h1>RSVP</h1>
      <button
        class="btn btn-primary btn-raised"
        id="back"
        on:click|preventDefault={goBack}>Back</button
      >
    </div>
    <RSVPForm
      invitee={$rsvp.first_name[0] + " " + $rsvp.last_name[0]}
      {hasPlusOne}
    />
  {/if}
</div>

<style>
  .container {
    max-width: 50rem;
    margin: 1rem;
  }
  .align-space-between {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: baseline;
  }
  a:visited {
    color: var(--link);
  }
  .right-box {
    display: flex;
    align-content: center;
  }
  .align-right {
    display: flex;
    align-content: center;
  }
  .search-button {
    margin-left: 0.2em;
  }
  .plain {
    white-space: pre-wrap;
  }
  .message {
    margin: 20px 0px 24px;
  }
  #search {
    border-radius: 9px;
    border: 2px var(--home-link-before) inset;
    padding: 5px 5px 5px 10px;
    color: var(--text);
    letter-spacing: var(--letter-spacing);
    background-color: var(--button-focus);
  }
  form {
    margin-top: 2em;
    padding: 1rem 1rem 0.1rem 1rem;
  }
  #top {
    padding-top: 0.75rem;
  }
  #main-form {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin: 20px 10px 10px 10px;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
    margin-top: -45px;
    margin-bottom: -15px;
  }
  :global(.poem-body) {
    height: 10rem !important;
  }
  @media (max-width: 900px) {
    #main-form {
      width: 85%;
      margin: 20px 10px 10px 10px;
    }
  }
</style>
