<script>
    import { functions } from "../functions"
    import { onMount } from "svelte";
    export let specialClass = "";
    export let sizeClass = "";
    export let containerId = "";
    export let label = "";
    export let name = "";
    export let inputId = "";
    export let required = "required";
    export let readonly = "";
    export let value = "";
    export let description = "";
    export let customFunction = "";

    onMount(() => {
      functions.mountFormActions()
    })
</script>

<div class="{sizeClass}">
    <div class="form-group bmd-form-group focusable {specialClass}" id="{containerId}">
      <div class="bmd-label-floating"> {label} </div>
      <input class="form-control" name="{name}"
      on:focus={functions.activate} 
      on:focus={functions.underline} 
      on:blur={functions.deactivate} 
      on:blur={functions.deunderline} 
      on:change={functions.saveInput}
      on:keydown={customFunction}
      type="text" id="{inputId}" {required} {readonly} bind:value={value} />
    </div>
    <p>{description}</p>
</div>

<style>
  p {
    color: var(--description-text);
    font-size: 12px;
    margin-top: -14px;
  }
</style>