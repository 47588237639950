<script>
  import { loading, rsvp, search, plus } from "./store.js";
  import { onMount } from "svelte";
  import { functions } from "../functions";
  import Loader from "./LoadingSpinner.svelte";
  import axios from "axios";
  import InputText from "./Input-Text.svelte";
  import InputSelect from "./Input-Select.svelte";
  import InputRadio from "./Input-Radio.svelte";
  import InputTextArea from "./Input-TextArea.svelte";
  export let invitee = "";
  export let hasPlusOne = false;
  let submitted = false;
  let submit = false;
  let searched = false;
  let previousRSVP;

  onMount(async () => {
    previousRSVP = await functions.getRSVPs(invitee);
    functions.mountFormActions();
    await new Promise((resolve) => setTimeout(resolve, 300)); // fake loading looks better
    functions.mountFormActions();
  });

  function allRequiredFields() {
    const unfilledRequirements = [];
    const unfilledRequirementNames = [];
    let forms = document.getElementsByTagName("form");
    const radioElements = [];
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      radioElements.push(
        Array.from(form.elements).filter((el) => el.type === "radio")
      );
    }
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      for (let j = 0; j < form.elements.length; j++) {
        if (
          form.elements[j].value === "" &&
          form.elements[j].hasAttribute("required")
        ) {
          unfilledRequirements.push(form.elements[j]);
        }
      }
    }
    for (let i = 0; i < radioElements.length; i++) {
      const yes = radioElements[i][0].checked;
      const no = radioElements[i][1].checked;
      if (!yes && !no) {
        unfilledRequirements.push(radioElements[i][0]);
      }
    }
    for (let i = 0; i < unfilledRequirements.length; i++) {
      const el = unfilledRequirements[i];
      el.classList.add("is-not-filled");
      if (i === 0) {
        unfilledRequirementNames.push(el.name);
      } else if (i === unfilledRequirements.length - 1) {
        unfilledRequirementNames.push(" and " + el.name);
      } else {
        unfilledRequirementNames.push(" " + el.name);
      }
    }
    if (unfilledRequirementNames[1]) {
      alert(unfilledRequirementNames + " fields are required.");
      return false;
    } else if (unfilledRequirementNames[0]) {
      alert(unfilledRequirementNames[0] + " field is required.");
      return false;
    }
  }

  function clearForm() {
    if (submitted)
      document.getElementById("submit-button").classList.remove("btn-primary");
    document.querySelectorAll("input, textarea").forEach((item) => {
      item.value = "";
      item.classList.remove("is-filled");
      item.parentNode.classList.remove("is-focus");
      localStorage.setItem(item.id, item.value);
    });
  }

  async function submitForm() {
    if (allRequiredFields() === false) {
      return;
    }
    $loading = true;
    let payload = {};

    payload["first_name"] = [$rsvp.first_name[0]];
    payload["last_name"] = [$rsvp.last_name[0]];
    payload["attending"] = [$rsvp.attending[0]];
    payload["food"] = [$rsvp.food[0]];
    payload["food_details"] = [$rsvp.food_details[0]];
    payload["songs"] = $rsvp.songs;
    if ($rsvp.first_name[1] !== undefined) {
      for (let i = 1; i < $rsvp.first_name.length; i++) {
        payload["first_name"].push($rsvp.first_name[i]);
        payload["last_name"].push($rsvp.last_name[i]);
        payload["attending"].push($rsvp.attending[i]);
        payload["food"].push($rsvp.food[i]);
        payload["food_details"].push($rsvp.food_details[i]);
      }
    }
    if ($plus.name[0] !== undefined) {
      payload["a_name"] = [$plus.name[0]];
      payload["a_attending"] = [$plus.attending[0]];
      payload["a_food"] = [$plus.food[0]];
      payload["a_food_details"] = [$plus.food_details[0]];
    }
    if ($plus.name[1] !== undefined) {
      for (let i = 1; i < $plus.name.length; i++) {
        payload["a_name"].push($plus.name[i]);
        payload["a_attending"].push($plus.attending[i]);
        payload["a_food"].push($plus.food[i]);
        payload["a_food_details"].push($plus.food_details[i]);
      }
    }
    try {
      await new Promise((resolve) => setTimeout(resolve, 300)); // fake loading looks better
      let resp =
        previousRSVP.length < 1
          ? await axios.post("/api/rsvp/submit", payload)
          : await axios.put("/api/rsvp/update", payload);
      if (resp.data === "rsvp submitted." || resp.data === "rsvp updated.") {
        document
          .getElementById("submit-button")
          .classList.remove("btn-primary");
        await new Promise((resolve) => setTimeout(resolve, 300)); // fake loading looks better
        document.getElementById("submit-button").classList.add("btn-primary");
        submitted = true;
        $loading = false;
      }
      clearForm();
      window.location.assign("/rsvp-received");
    } catch (err) {
      await new Promise((resolve) => setTimeout(resolve, 200));
      $loading = false;
      console.log(`Error:  ${err}`);
      if (err.message === "Request failed with status code 423") {
        alert(`It looks like you've already RSVP'd, thank you!`);
      } else {
        alert(`something went wrong`);
      }
    }
  }

  const handleSubmit = () => {
    submit = true;
  };

  const handleKeyup = (key) => {
    submit = false;

    if (key.code === "Enter") {
      key.preventDefault();
      key.target.value;
      value = key.target.value;
      return false;
    }
  };

  $: sizeClass = "";
  $: innerWidth = 0;
  $: {
    if (innerWidth >= 600) {
      sizeClass = "";
    } else {
      sizeClass = [
        "col-full",
        "col-two-thirds",
        "col-half",
        "col-third",
        "col-fourth",
      ];
    }
  }

  const getAddtitions = functions.getInviteAdditions(invitee);
</script>

{#await functions.getRSVPs(invitee) then currentRSVP}
  {#if currentRSVP.length !== 0}
    <div class="message">
      You've already RSVP'd, feel free to make any changes you desire.
    </div>
  {/if}
{/await}
<form on:submit|preventDefault={handleSubmit} id="main-form">
  <div class="row" id="top">
    <div style="display: none;" />
    <InputText
      sizeClass={sizeClass === "" ? "col-half" : sizeClass[2]}
      containerId="name_container"
      label="Name"
      name="Name"
      inputId="name"
      required="required"
      readonly
      value={$rsvp.first_name[0] + " " + $rsvp.last_name[0]}
    />
    <InputRadio
      sizeClass={sizeClass === "" ? "col-sixth" : sizeClass[3]}
      containerId="attending_container"
      label="Attending"
      name="Attending"
      inputId="attending"
      required="required"
      options={[{ Yes: 1 }, { No: 0 }]}
      bind:value={$rsvp.attending[0]}
      description=""
    />
  </div>
  <br />
  <br />
  <br />
  <div class="row">
    {#if $rsvp.attending[0] !== 0}
      <InputSelect
        sizeClass={sizeClass === "" ? "col-fourth" : sizeClass[2]}
        containerId="food_container"
        label="Your dietary preferences"
        name="Dietary preferences"
        inputId="food"
        required=""
        options={[
          ["", "None"],
          ["vegetarian", "Vegetarian"],
          ["vegan", "Vegan"],
          ["gluten", "Gluten free"],
          ["kosher", "Kosher"],
          ["other", "Other"],
        ]}
        bind:value={$rsvp.food[0]}
      />
      {#if $rsvp.food[0] === "other"}
        {#if sizeClass === ""}
          <div class="col-fourth" />
          <div class="col-fourth" />
        {/if}
        <InputText
          sizeClass={sizeClass === "" ? "col-fourth" : sizeClass[3]}
          containerId="food_details_container"
          label="Details"
          name="food_details"
          inputId="food_details"
          required=""
          bind:value={$rsvp.food_details[0]}
          description="Please describe your dietary preferences"
        />
      {/if}
      <InputTextArea
        sizeClass={sizeClass === "" ? "col-fourth" : sizeClass[0]}
        containerId="songs_container"
        specialClass="smaller-textbox"
        label="Song requests..."
        name="songs"
        inputId="songs"
        required=""
        bind:value={$rsvp.songs}
        description="If you have any songs you'd like to hear, let us know!"
      />
    {:else}
      <h3
        style="margin-top: 10px; margin-bottom: auto;"
        class={sizeClass === "" ? "col-two-thirds" : sizeClass[0]}
      >
        We'll miss you 🤍
      </h3>
    {/if}
  </div>
  <br />
  <br />
</form>
{#await getAddtitions}
  <div style="display: none;" />
{:then adds}
  {#each adds as add, i}
    <div style="display: none;">
      {($rsvp.first_name[i + 1] = add.first_name)}{($rsvp.last_name[i + 1] =
        add.last_name)}
    </div>
    <form on:submit|preventDefault={handleSubmit} id="main-form">
      <div class="row" id="top">
        <div style="display: none;" />
        <InputText
          sizeClass={sizeClass === "" ? "col-half" : sizeClass[2]}
          containerId="name_container"
          label="Name"
          name="Name"
          inputId="name"
          required="required"
          readonly
          value={add.first_name + " " + add.last_name}
        />
        <InputRadio
          sizeClass={sizeClass === "" ? "col-sixth" : sizeClass[3]}
          containerId="attending_container"
          label="Attending"
          name="Attending"
          inputId="attending"
          required="required"
          options={[{ Yes: 1 }, { No: 0 }]}
          bind:value={$rsvp.attending[i + 1]}
          description=""
        />
      </div>
      <br />
      <br />
      <br />
      <div class="row">
        {#if $rsvp.attending[i + 1] !== 0}
          <InputSelect
            sizeClass={sizeClass === "" ? "col-fourth" : sizeClass[2]}
            containerId="food_container"
            label="Your dietary preferences"
            name="Dietary preferences"
            inputId="food"
            required=""
            options={[
              ["", "None"],
              ["vegetarian", "Vegetarian"],
              ["vegan", "Vegan"],
              ["gluten", "Gluten free"],
              ["kosher", "Kosher"],
              ["other", "Other"],
            ]}
            bind:value={$rsvp.food[i + 1]}
          />
          {#if $rsvp.food[i + 1] === "other"}
            {#if sizeClass === ""}
              <div class="col-fourth" />
              <div class="col-fourth" />
            {/if}
            <InputText
              sizeClass={sizeClass === "" ? "col-fourth" : sizeClass[3]}
              containerId="food_details_container"
              label="Details"
              name="food_details"
              inputId="food_details"
              required=""
              bind:value={$rsvp.food_details[i + 1]}
              description="Please describe your dietary preferences"
            />
          {/if}
        {:else}
          <h3
            style="margin-top: 10px; margin-bottom: auto;"
            class={sizeClass === "" ? "col-two-thirds" : sizeClass[0]}
          >
            We'll miss you 🤍
          </h3>
        {/if}
      </div>
      <br />
      <br />
    </form>
  {/each}
{/await}
{#if !isNaN(parseInt(hasPlusOne))}
  <div class="message">
    Looks like you have a +{hasPlusOne}! <br />
  </div>
  {#each Array.from(Array(hasPlusOne).keys()) as addition, i}
    <form on:submit|preventDefault={handleSubmit} id="main-form">
      <div class="row" id="top">
        <div style="display: none;" />
        <InputText
          sizeClass={sizeClass === "" ? "col-half" : sizeClass[2]}
          containerId={"name_container"}
          label={"Name"}
          name={"Name"}
          inputId={"name"}
          required="required"
          bind:value={$plus.name[i]}
        />
        <InputRadio
          sizeClass={sizeClass === "" ? "col-sixth" : sizeClass[3]}
          containerId={"attending_container"}
          label={"Attending"}
          name={"Attending"}
          inputId={"attending"}
          required="required"
          options={[{ Yes: 1 }, { No: 0 }]}
          bind:value={$plus.attending[i]}
          description=""
        />
      </div>
      <br />
      <br />
      <br />
      <div class="row">
        {#if $plus.attending[i] !== 0}
          <InputSelect
            sizeClass={sizeClass === "" ? "col-fourth" : sizeClass[2]}
            containerId={"food_container"}
            label={"Your dietary preferences"}
            name={"Dietary preferences"}
            inputId={"food"}
            required=""
            options={[
              ["", "None"],
              ["vegetarian", "Vegetarian"],
              ["vegan", "Vegan"],
              ["gluten", "Gluten free"],
              ["kosher", "Kosher"],
              ["other", "Other"],
            ]}
            bind:value={$plus.food[i]}
          />
          {#if $plus.food[i] === "other"}
            {#if sizeClass === ""}
              <div class="col-fourth" />
              <div class="col-fourth" />
            {/if}
            <InputText
              sizeClass={sizeClass === "" ? "col-fourth" : sizeClass[3]}
              containerId={"food_details_container"}
              label={"Details"}
              name={"food_details"}
              inputId={"food_details"}
              required=""
              bind:value={$plus.food_details[i]}
              description="Please describe your dietary preferences"
            />
          {/if}
        {:else}
          <h3
            style="margin-top: 10px; margin-bottom: auto;"
            class={sizeClass === "" ? "col-two-thirds" : sizeClass[0]}
          >
            We'll miss you 🤍
          </h3>
        {/if}
      </div>
      <br />
      <br />
    </form>
  {/each}
{/if}
<div class="align-right">
  <button
    class="btn btn-primary btn-raised"
    id="submit-button"
    on:click|preventDefault={submitForm}
    on:keyup={handleKeyup}>Submit</button
  >
</div>
<br />
<br />

<style>
  .align-space-between {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: baseline;
  }
  :global(.smaller-textbox) {
    height: 5em !important;
  }
  .right {
    display: flex;
    align-content: center;
  }
  .align-right {
    display: flex;
    align-content: center;
  }
  .search-button {
    margin-left: 0.2em;
  }
  .plain {
    white-space: pre-wrap;
  }
  .message {
    margin: 20px 0px 24px;
  }
  #search {
    border-radius: 9px;
    border: 2px var(--home-link-before) inset;
    padding: 5px 5px 5px 10px;
    color: var(--text);
    letter-spacing: var(--letter-spacing);
    background-color: var(--button-focus);
  }
  form {
    margin-top: 2em;
    padding: 1rem 1rem 0.1rem 1rem;
  }
  #top {
    padding-top: 0.75rem;
  }
  #main-form {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin: 20px 10px 10px 10px;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
    margin-top: -45px;
    margin-bottom: -15px;
  }
  :global(.poem-body) {
    height: 10rem !important;
  }
  @media (max-width: 900px) {
    #main-form {
      width: 85%;
      margin: 20px 10px 10px 10px;
    }
  }
</style>
