<script>
  export let title;
  export let description;
</script>

<svelte:head>
  {#if title}
    <title>{"katie & ian - " + title}</title>
    <meta name="title" content={"katie & ian - " + title} />
    <meta property="og:title" content={"katie & ian - " + title} />
    <meta property="twitter:title" content={"katie & ian - " + title} />
  {/if}

  {#if description}
    <meta name="description" content={description} />
    <meta property="og:description" content={description} />
    <meta property="twitter:description" content={description} />
  {/if}
</svelte:head>
