<script>
  import Meta from "../components/Meta.svelte";
  import TopNav from "../components/Top-Nav.svelte";
  import Footer from "../components/Footer.svelte";
  import Detail from "../components/Detail.svelte";
  import { onMount } from "svelte";
  import { ianMode, theme } from "../components/store";

  $: innerWidth = 0;
  $: screen = "portrait";
  $: {
    if (innerWidth >= 600) {
      screen = "landscape";
    } else {
      screen = "portrait";
    }
  }

  let anchor = "";
  let height;
  let bottom = false;

  onMount(async () => {
    await new Promise((resolve) => setTimeout(resolve, 1));

    if (localStorage.getItem("ianMode") === null) {
      localStorage.setItem("ianMode", "false");
    }
    localStorage.getItem("ianMode") === "false"
      ? ($ianMode = "true")
      : ($ianMode = "false");
    localStorage.getItem("ianMode") === "false"
      ? document.documentElement.setAttribute("data-theme", "none")
      : document.documentElement.setAttribute("data-theme", "dark");
    localStorage.getItem("ianMode") === "true"
      ? (document.getElementById("toggle").checked = true)
      : (document.getElementById("toggle").checked = false);
    await new Promise((resolve) => setTimeout(resolve, 100));
    anchor = window.location.hash;
    if (anchor !== "") {
      document
        .querySelector(anchor)
        .scrollIntoView({ behavior: "smooth", block: "start" });
    }
    await new Promise((resolve) => setTimeout(resolve, 100));
    height = document.scrollingElement.offsetHeight;
  });

  let y;

  function atTheBottom(event) {
    if (
      window.innerHeight + window.scrollY + 25 >=
      document.body.offsetHeight
    ) {
      bottom = true;
    } else {
      bottom = false;
    }
  }
</script>

<svelte:window bind:innerWidth bind:scrollY={y} on:scroll={atTheBottom} />

<Meta title="Details" />

<TopNav marginBottom="0px" />

<div class="detail">
  <h1 style="text-align: center; margin-top: 5rem;">Details</h1>
  <div class="detail-center">
    <div id="events" class="line" />
    <div class="detail-container">
      <Detail
        containerId="events"
        {screen}
        header="Events"
        image="/photos/IMG_0018-small50.jpg"
        specialClass="no-bullets"
        imgSize="25"
        imgClass={screen === "portrait" ? "long-" : ""}
        description={[
          `<b>Ceremony</b>:<br><i>All Souls Anglican Church</i><br> 02/17/2024 | 4:00pm<br> ${
            window.navigator.userAgent.toLowerCase().includes("iphone")
              ? '<a href="https://maps.apple.com/?address=1101%20Bainbridge%20St,%20Richmond,%20VA%2023224%20Rd&t=m"><u>1101 Bainbridge St. Richmond, VA</u></a>'
              : '<a href="https://maps.app.goo.gl/qSRx2WFJaw8867Db9"><u>1101 Bainbridge St. Richmond, VA</u></a>'
          }`,
          `<br><b>Reception</b>:<br><i>Studio Two Three</i><br> 02/17/2024 | To follow ceremony<br> ${
            window.navigator.userAgent.toLowerCase().includes("iphone")
              ? '<a href="https://maps.apple.com/?address=3300%20W%20Clay%20St,%20Richmond,%20VA%2023230%20Rd&t=m"><u>3300 West Clay St. Richmond, VA</u></a>'
              : '<a href="https://maps.app.goo.gl/tUeUivXVNAkpQWZbA"><u>3300 West Clay St. Richmond, VA</u></a>'
          }`,
        ]}
      />
      <!-- `<b>Rehearsal Dinner</b>:<br><i>Buttermilk and Honey</i><br> 11/03/2023 | 6:30pm<br> ${window.navigator.userAgent.toLowerCase().includes('iphone') ? '<a href="https://maps.apple.com/?address=415%20E%20Grace%20St,%20Richmond,%20VA%20%2023219,%20United%20States&auid=6742259558494623190&ll=37.541974,-77.438626&lsp=9902&q=Buttermilk%20and%20Honey&t=m"><u>415 E Grace St, Richmond, VA 23219</u></a>' : '<a href="https://www.google.com/maps/place/Buttermilk+and+Honey+on+Grace/@37.5420967,-77.4410214,17z/data=!3m1!4b1!4m6!3m5!1s0x89b1113cf6036cd7:0xb91dbd5fac487b06!8m2!3d37.5420925!4d-77.4384465!16s%2Fg%2F11bwqflw_9"><u>415 E Grace St, Richmond, VA 23219</u></a>'}<br>`, -->
    </div>
    <div id="dress_code" class="line" />
    <div class="detail-container">
      <Detail
        containerId="dress"
        {screen}
        header="Dress Code"
        specialClass=""
        subHeader="Cocktail Attire"
      />
      <!-- description={[
          "Cocktail dresses or dressy jumpsuits ",
          "Suits or sports jackets and slacks",
        ]} -->
    </div>
    <div id="parking" class="line" />
    <div class="detail-container">
      <Detail
        containerId="parking"
        {screen}
        header="Parking"
        specialClass="iframe"
        subHeader="Street parking is available around both the church and the reception space."
      />
      <!-- description={[
          '<div style="width: 100%"><iframe class="map" width="100%" height="450" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=450&amp;hl=en&amp;q=1060%20W%20Addison%20St%20Chicago,%20IL%2060613+(Ceremony)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div>',
        ]} -->
    </div>
    <div id="accommodations" class="line" />
    <div class="detail-container">
      <Detail
        containerId="accommodations"
        {screen}
        header="Accommodations"
        specialClass=""
        subHeader="We have reserved a block of rooms at TBD. <br><br> <b>Please note, our block of rooms will only be held until TBD</b>"
      />
      <!-- description={[
          'To book with our access code, use <a style="text-decoration: underline; font-weight: 500;" target="_blank" rel="noopener noreferrer" href="https://www.marriott.com/events/start.mi?id=1690390294578&key=GRP">Marriott\'s website</a>',
        ]} -->
    </div>
    <!-- <div id="transportation" class="line" /> -->
    <!-- <div class="detail-container">
      <Detail
        containerId="transportation"
        {screen}
        header="Transportation"
        subHeader="No transportation will be provided, get ya own ride."
      />
    </div> -->
    <div id="registry" class="line" />
    <div class="detail-container">
      <Detail
        containerId="registry"
        {screen}
        header="Registry"
        subHeader="Here's where we're registered. Feel free to use other avenues if desired."
        description={[
          '<a target="_blank" rel="noopener noreferrer" href="https://www.crateandbarrel.com/gift-registry/katie-arthur-and-ian-buchanan/r6825681">Crate & Barrel 🛢️</a>',
          '<a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/wedding/registry/19IH31V7VNZQ6">Amazon 📦</a>',
          '<a target="_blank" rel="noopener noreferrer" href="https://www.target.com/gift-registry/gift-giver?registryId=fbe70690-8d17-11ee-a8d6-a56ca8604f22&type=WEDDING">Target 🎯</a>',
        ]}
      />
    </div>
    <div class="line" style="margin-bottom: 10rem;" />
  </div>
</div>

<Footer marginBottom="0px" />

<style>
  .detail-center {
    margin: auto;
    width: 50rem;
    max-width: 100%;
    background-color: rgb(206 205 195 / 25%);
    position: relative;
  }
  .detail {
    margin: auto;
    overflow: auto;
    position: relative;
  }
  .detail-container {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .parallax-container {
    position: fixed;
    width: 125vw;
    height: 12px;
    left: 50%;
    z-index: -100;
    transform: translate(-50%, -11%);
  }

  .parallax-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    will-change: transform;
  }

  .parallax-container img:last-child::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(45, 10, 13);
  }

  @media only screen and (min-width: 100px) {
    /* styles for browsers larger than 960px; */
    .parallax-container {
      width: 400vw;
    }
  }
  @media only screen and (min-width: 760px) {
    /* styles for browsers larger than 960px; */
    .parallax-container {
      width: 250vw;
    }
  }

  @media only screen and (min-width: 960px) {
    /* styles for browsers larger than 960px; */
    .parallax-container {
      width: 200vw;
    }
  }
  @media only screen and (min-width: 1130px) {
    /* styles for browsers larger than 1240px; */
    .parallax-container {
      width: 145vw;
    }
  }
  @media only screen and (min-width: 1240px) {
    /* styles for browsers larger than 1240px; */
    .parallax-container {
      width: 135vw;
    }
  }
  @media only screen and (min-width: 1360px) {
    /* styles for browsers larger than 1240px; */
    .parallax-container {
      width: 121vw;
    }
  }
  @media only screen and (min-width: 1440px) {
    /* styles for browsers larger than 1440px; */
    .parallax-container {
      width: 110vw;
    }
  }
  @media only screen and (min-width: 1590px) {
    /* styles for browsers larger than 1440px; */
    .parallax-container {
      width: 100vw;
    }
  }
  @media only screen and (min-width: 2000px) {
    /* for sumo sized (mac) screens */
    .parallax-container {
      width: 100vw;
    }
  }
  @media only screen and (max-device-width: 480px) {
    /* styles for mobile browsers smaller than 480px; (iPhone) */
    .parallax-container {
      width: 400vw;
    }
  }
  @media only screen and (device-width: 768px) {
    /* default iPad screens */
    .parallax-container {
      width: 250vw;
    }
  }
  /* different techniques for iPad screening */
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* For portrait layouts only */
    .parallax-container {
      width: 250vw;
    }
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* For landscape layouts only */
    .parallax-container {
      width: 150vw;
    }
  }
</style>
