<script>
  import { onDestroy } from "svelte";
  import { timer } from "./store";

  const preloadImageUrlsDesktop = [
    "assets/flying-heart/flying-heart0.png",
    "assets/flying-heart/flying-heart1.png",
    "assets/flying-heart/flying-heart2.png",
    "assets/flying-heart/flying-heart3.png",
    "assets/flying-heart/flying-heart4.png",
    "assets/flying-heart/flying-heart5.png",
    "assets/flying-heart/flying-heart6.png",
    "assets/flying-heart/flying-heart7.png",
    "assets/flying-heart/flying-heart8.png",
    "assets/flying-heart/flying-heart9.png",
    "assets/flying-heart/flying-heart10.png",
    "assets/flying-heart/flying-heart11.png",
    "assets/flying-heart/flying-heart12.png",
    "assets/flying-heart/flying-heart13.png",
    "assets/flying-heart/flying-heart14.png",
    "assets/flying-heart/flying-heart15.png",
    "assets/flying-heart/flying-heart16.png",
    "assets/flying-heart/flying-heart17.png",
    "assets/flying-heart/flying-heart18.png",
    "assets/flying-heart/flying-heart19.png",
    "assets/flying-heart/flying-heart20.png",
    "assets/flying-heart/flying-heart21.png",
    "assets/flying-heart/flying-heart22.png",
    "assets/flying-heart/flying-heart23.png",
    "assets/flying-heart/flying-heart24.png",
    "assets/flying-heart/flying-heart25.png",
    "assets/flying-heart/flying-heart26.png",
    "assets/flying-heart/flying-heart27.png",

    "assets/flying-heart/flying-heart-ending0.png",
    "assets/flying-heart/flying-heart-ending1.png",
    "assets/flying-heart/flying-heart-ending2.png",
    "assets/flying-heart/flying-heart-ending3.png",
    "assets/flying-heart/flying-heart-ending4.png",
    "assets/flying-heart/flying-heart-ending5.png",
    "assets/flying-heart/flying-heart-ending6.png",
    "assets/flying-heart/flying-heart-ending7.png",
    "assets/flying-heart/flying-heart-ending8.png",
    "assets/flying-heart/flying-heart-ending9.png",
    "assets/flying-heart/flying-heart-ending10.png",
    "assets/flying-heart/flying-heart-ending11.png",
    "assets/flying-heart/flying-heart-ending12.png",
    "assets/flying-heart/flying-heart-ending13.png",
    "assets/flying-heart/flying-heart-ending14.png",
    "assets/flying-heart/flying-heart-ending15.png",
    "assets/flying-heart/flying-heart-ending16.png",
    "assets/flying-heart/flying-heart-ending17.png",
    "assets/flying-heart/flying-heart-ending18.png",
    "assets/flying-heart/flying-heart-ending19.png",
    "assets/flying-heart/flying-heart-ending20.png",
    "assets/flying-heart/flying-heart-ending21.png",
    "assets/flying-heart/flying-heart-ending22.png",

    "assets/flying-heart/flying-heart-ending-repeat0.png",
    "assets/flying-heart/flying-heart-ending-repeat1.png",
    "assets/flying-heart/flying-heart-ending-repeat-inverted0.png",
    "assets/flying-heart/flying-heart-ending-repeat-inverted1.png",
  ];

  const preloadImageUrlsPhone = [
    "assets/flying-heart/flying-heart0-phone.png",
    "assets/flying-heart/flying-heart1-phone.png",
    "assets/flying-heart/flying-heart2-phone.png",
    "assets/flying-heart/flying-heart3-phone.png",
    "assets/flying-heart/flying-heart4-phone.png",
    "assets/flying-heart/flying-heart5-phone.png",
    "assets/flying-heart/flying-heart6-phone.png",
    "assets/flying-heart/flying-heart7-phone.png",
    "assets/flying-heart/flying-heart8-phone.png",
    "assets/flying-heart/flying-heart9-phone.png",
    "assets/flying-heart/flying-heart10-phone.png",
    "assets/flying-heart/flying-heart11-phone.png",
    "assets/flying-heart/flying-heart12-phone.png",
    "assets/flying-heart/flying-heart13-phone.png",
    "assets/flying-heart/flying-heart14-phone.png",
    "assets/flying-heart/flying-heart15-phone.png",
    "assets/flying-heart/flying-heart16-phone.png",
    "assets/flying-heart/flying-heart17-phone.png",
    "assets/flying-heart/flying-heart18-phone.png",
    "assets/flying-heart/flying-heart19-phone.png",
    "assets/flying-heart/flying-heart20-phone.png",
    "assets/flying-heart/flying-heart21-phone.png",
    "assets/flying-heart/flying-heart22-phone.png",
    "assets/flying-heart/flying-heart23-phone.png",
    "assets/flying-heart/flying-heart24-phone.png",
    "assets/flying-heart/flying-heart25-phone.png",
    "assets/flying-heart/flying-heart26-phone.png",
    "assets/flying-heart/flying-heart27-phone.png",

    "assets/flying-heart/flying-heart-ending0-phone.png",
    "assets/flying-heart/flying-heart-ending1-phone.png",
    "assets/flying-heart/flying-heart-ending2-phone.png",
    "assets/flying-heart/flying-heart-ending3-phone.png",
    "assets/flying-heart/flying-heart-ending4-phone.png",
    "assets/flying-heart/flying-heart-ending5-phone.png",
    "assets/flying-heart/flying-heart-ending6-phone.png",
    "assets/flying-heart/flying-heart-ending7-phone.png",
    "assets/flying-heart/flying-heart-ending8-phone.png",
    "assets/flying-heart/flying-heart-ending9-phone.png",
    "assets/flying-heart/flying-heart-ending10-phone.png",
    "assets/flying-heart/flying-heart-ending11-phone.png",
    "assets/flying-heart/flying-heart-ending12-phone.png",
    "assets/flying-heart/flying-heart-ending13-phone.png",
    "assets/flying-heart/flying-heart-ending14-phone.png",
    "assets/flying-heart/flying-heart-ending15-phone.png",
    "assets/flying-heart/flying-heart-ending16-phone.png",
    "assets/flying-heart/flying-heart-ending17-phone.png",
    "assets/flying-heart/flying-heart-ending18-phone.png",
    "assets/flying-heart/flying-heart-ending19-phone.png",
    "assets/flying-heart/flying-heart-ending20-phone.png",
    "assets/flying-heart/flying-heart-ending21-phone.png",
    "assets/flying-heart/flying-heart-ending22-phone.png",

    "assets/flying-heart/flying-heart-ending-repeat0-phone.png",
    "assets/flying-heart/flying-heart-ending-repeat1-phone.png",
    "assets/flying-heart/flying-heart-ending-repeat-inverted0-phone.png",
    "assets/flying-heart/flying-heart-ending-repeat-inverted1-phone.png",
  ];
  let counter = 0;
  let duration = 2000000;
  const counterRounded = (counter) => Math.round((counter / 1000).toFixed(1));

  let last_time = window.performance.now();
  let frame;
  let ending = "";
  let repeat = "";
  let inverted = "";
  let elapsed = 0;
  let speed = 8;

  (function update() {
    frame = requestAnimationFrame(update);

    const time = window.performance.now();
    counter += Math.min(time - last_time, duration - counter) * speed;
    elapsed += Math.min(time - last_time, duration - elapsed) * speed;
    timer.set(elapsed);

    if (counterRounded(counter) === 27 && ending === "" && repeat === "") {
      counter = 0;
      ending = "-ending";
    }
    if (
      counterRounded(counter) === 22 &&
      ending === "-ending" &&
      repeat === ""
    ) {
      counter = 0;
      repeat = "-repeat";
    }
    if (
      counterRounded(elapsed) % 29 === 0 &&
      ending === "-ending" &&
      repeat === "-repeat"
    ) {
      inverted = inverted === "-inverted" ? "" : "-inverted";
    }
    if (
      ending === "-ending" &&
      repeat === "-repeat" &&
      counterRounded(counter) === 2
    ) {
      counter = 0;
      speed = 4;
    }
    if (
      ending === "-ending" &&
      repeat === "-repeat" &&
      counterRounded(counter) > 3
    ) {
      counter = 0;
    }
    last_time = time;
  })();

  onDestroy(() => {
    cancelAnimationFrame(frame);
  });
  $: innerWidth = 0;
  $: innerHeight = 0;
  $: screen = "large";
  $: {
    if (innerWidth >= 600) {
      screen = "large";
    } else {
      screen = "small";
    }
  }
  function resetSlideShow() {
    counter = 0;
    elapsed = 0;
    ending = "";
    repeat = "";
    inverted = "";
  }
</script>

<svelte:window bind:innerWidth bind:innerHeight />
<svelte:head>
  {#if screen === "small"}
    {#each preloadImageUrlsPhone as image}
      <link rel="preload" as="image" href={image} />
    {/each}
  {:else}
    {#each preloadImageUrlsDesktop as image}
      <link rel="preload" as="image" href={image} />
    {/each}
  {/if}
</svelte:head>

<!-- <div class="timer">{counterRounded(counter)}s</div> -->

<div class="flying-heart-container">
  <!-- <img src="/assets/flying-heart/flying-heart0.png" alt="flying heart" /> -->
  <img
    src="/assets/flying-heart/flying-heart{ending}{repeat}{inverted}{counterRounded(
      counter
    )}{screen === 'small' ? '-phone' : ''}.png"
    alt="flying heart"
  />
</div>

<style>
  .flying-heart-container {
    display: flex;
    justify-content: center;
    margin-top: 72px;
  }
  img {
    width: 75%;
  }
</style>
