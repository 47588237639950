<script>
  import { onMount } from "svelte";
  import Confetti from "../components/Confetti.svelte";
  import Meta from "../components/Meta.svelte";
  import TopNav from "../components/Top-Nav.svelte";
  import Footer from "../components/Footer.svelte";
  import Animation from "../components/Animation.svelte";
  import Button from "../components/Button.svelte";
  import { theme, ianMode, timer } from "../components/store";
  import Slideshow from "../components/Slideshow.svelte";
  const preloadImageUrls = [
    "/assets/flower-confetti-0.png",
    "/assets/flower-confetti-1.png",
    "/assets/flower-confetti-2.png",
    "/assets/flower-confetti-3.png",
    "/assets/flower-confetti-4.png",
    "/assets/flower-confetti-5.png",
    "/assets/flower-confetti-6.png",
    "/assets/flower-confetti-7.png",
    "/assets/flower-confetti-8.png",
    "/assets/flower-confetti-9.png",
    "/assets/flower-confetti-10.png",
    "/assets/flower-confetti-11.png",
    "/assets/flower-confetti-12.png",
    "/assets/flower-confetti-13.png",
    "/assets/flower-confetti-14.png",
    "/assets/flower-confetti-15.png",
    "/assets/flower-confetti-16.png",
  ];

  let showLinks = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  };
  let show = false;
  let homelinksMarginTop = {
    small: "-10rem",
    large: "-20rem",
  };

  async function showTransition() {
    show = false;
    await new Promise((resolve) => setTimeout(resolve, 50));
    showLinks[1] = true;
    show = true;
    await new Promise((resolve) => setTimeout(resolve, 800));
    showLinks[2] = true;
    await new Promise((resolve) => setTimeout(resolve, 350));
    showLinks[3] = true;
    await new Promise((resolve) => setTimeout(resolve, 350));
    showLinks[4] = true;
    await new Promise((resolve) => setTimeout(resolve, 350));
    showLinks[5] = true;
    await new Promise((resolve) => setTimeout(resolve, 350));
    showLinks[6] = true;
  }

  onMount(async () => {
    if (localStorage.getItem("ianMode") === null) {
      localStorage.setItem("ianMode", "true");
    }
    localStorage.getItem("ianMode") === "false"
      ? ($ianMode = "true") && localStorage.setItem("ianMode", "true")
      : ($ianMode = "false") && localStorage.setItem("ianMode", "false");
    localStorage.getItem("ianMode") === "false"
      ? theme.set("none")
      : theme.set("dark");
    localStorage.getItem("ianMode") === "false"
      ? document.documentElement.setAttribute("data-theme", "none")
      : document.documentElement.setAttribute("data-theme", "dark");
    localStorage.getItem("ianMode") === "true"
      ? (document.getElementById("toggle").checked = true)
      : (document.getElementById("toggle").checked = false);
  });
  $: $theme && showTransition();
  $: innerWidth = 0;
  $: innerHeight = 0;
  $: screen = "large";
  $: {
    if (innerWidth >= 600) {
      screen = "large";
    } else {
      screen = "small";
    }
  }
</script>

<svelte:window bind:innerWidth bind:innerHeight />
<svelte:head>
  {#each preloadImageUrls as image}
    <link rel="preload" as="image" href={image} />
  {/each}
</svelte:head>
<TopNav
  marginBottom="40px"
  onClick={async () => {
    show = !show;
    await new Promise((resolve) => setTimeout(resolve, 1));
    show = !show;
  }}
/>

<div class="home-wrapper">
  {#if $ianMode === "false"}
    <img
      class="katie-bg"
      src="/assets/blood_vessel_bg_40.png"
      alt="bloodvessel"
      style={innerWidth < 450 ? `width: 160vw!important` : ""}
    />
  {/if}
  {#if screen === "large"}
    <div class="sidebar" />
  {/if}
  <div class="katie-content">
    <Meta title="Wedding" />
    <div
      style="position: fixed; top: -50px; left: 0; height: 100vh; width: 100vw; display: flex; justify-content: center; overflow: hidden;"
    >
      {#if $ianMode === "false"}
        <!-- <h1 style="margin-top: 19rem;">confetti take this out</h1> -->
        <Confetti
          colorArray={[
            "url('/assets/flower-confetti-0.png')",
            "url('/assets/flower-confetti-1.png')",
            "url('/assets/flower-confetti-2.png')",
            "url('/assets/flower-confetti-3.png')",
            "url('/assets/flower-confetti-4.png')",
            "url('/assets/flower-confetti-5.png')",
            "url('/assets/flower-confetti-6.png')",
            "url('/assets/flower-confetti-7.png')",
            "url('/assets/flower-confetti-8.png')",
            "url('/assets/flower-confetti-9.png')",
            "url('/assets/flower-confetti-10.png')",
            "url('/assets/flower-confetti-11.png')",
            "url('/assets/flower-confetti-12.png')",
            "url('/assets/flower-confetti-13.png')",
            "url('/assets/flower-confetti-14.png')",
            "url('/assets/flower-confetti-15.png')",
            "url('/assets/flower-confetti-16.png')",
          ]}
          size="35"
          x={[-5, 5]}
          y={[0, 0.1]}
          delay={[500, 1000]}
          infinite
          duration="10000"
          amount="150"
          fallDistance="100vh"
        />
      {/if}
    </div>
    <div
      id="home"
      style={screen === "large"
        ? "max-width: 100%; overflow-x: hidden;"
        : "max-width: 105%; overflow-x: hidden;"}
    >
      <div class="home-cols">
        <div
          class="welcome"
          style={screen === "large"
            ? "width: 100%; background-position: 100% 35%; max-width: 50rem; margin-top: -4rem;"
            : "margin-top: -4rem;"}
        >
          <div
            class="overlay"
            style={screen === "large" ? "max-width: 50rem" : ""}
          >
            <!-- Overlay spot -->
            <div id="welcome-lights">
              <div>
                {#if $ianMode === "false"}
                  <Animation {show} />
                {:else}
                  <Slideshow />
                {/if}
              </div>
            </div>
            <div id="welcome-links">
              <ul
                class="home-links"
                style={$ianMode === "true" && $timer > 48000
                  ? `margin-top: ${homelinksMarginTop[screen]}`
                  : ""}
              >
                <li class="link-item">
                  <a
                    href="/rsvp"
                    class="home-link-item link {showLinks[1]
                      ? 'link-after'
                      : ''}"
                    id="rsvp">rsvp</a
                  >
                  <span
                    class="{window.navigator.userAgent
                      .toLowerCase()
                      .includes('mobile')
                      ? 'underline-mobile'
                      : 'underline'} {showLinks[1] ? 'underline-after' : ''}"
                    >rsvp</span
                  >
                </li>
                <li class="link-item">
                  <a
                    href="/details#events"
                    class="home-link-item link {showLinks[2]
                      ? 'link-after'
                      : ''}"
                    id="events">events</a
                  >
                  <span
                    class="{window.navigator.userAgent
                      .toLowerCase()
                      .includes('mobile')
                      ? 'underline-mobile'
                      : 'underline'} {showLinks[2] ? 'underline-after' : ''}"
                    >events</span
                  >
                </li>
                <li class="link-item">
                  <a
                    href="/details#accommodations"
                    class="home-link-item link {showLinks[3]
                      ? 'link-after'
                      : ''}"
                    id="accommodations">accommodations</a
                  >
                  <span
                    class="{window.navigator.userAgent
                      .toLowerCase()
                      .includes('mobile')
                      ? 'underline-mobile'
                      : 'underline'} {showLinks[3] ? 'underline-after' : ''}"
                    >accommodations</span
                  >
                </li>
                <li class="link-item">
                  <a
                    href="/details#registry"
                    class="home-link-item link {showLinks[4]
                      ? 'link-after'
                      : ''}"
                    id="registry">registry</a
                  >
                  <span
                    class="{window.navigator.userAgent
                      .toLowerCase()
                      .includes('mobile')
                      ? 'underline-mobile'
                      : 'underline'} {showLinks[4] ? 'underline-after' : ''}"
                    >registry</span
                  >
                </li>
                <li class="link-item">
                  <a
                    href="/photos"
                    class="home-link-item link {showLinks[5]
                      ? 'link-after'
                      : ''}"
                    id="photos">photos</a
                  >
                  <span
                    class="{window.navigator.userAgent
                      .toLowerCase()
                      .includes('mobile')
                      ? 'underline-mobile'
                      : 'underline'} {showLinks[5] ? 'underline-after' : ''}"
                    >photos</span
                  >
                </li>
              </ul>
              <ul
              class="home-links"
              style={$ianMode === "true" && $timer > 48000
                ? `margin-top: ${homelinksMarginTop[screen]}`
                : ""}
            >
          
            <li class="link-item">
              <a
                href="https://open.spotify.com/playlist/3wjUj4SHyrSp4x7gPPJlgp?si=0RoI8xy6RgeLqSavAMOj8g&pi=u-zIXgSHMjRCuC&nd=1&dlsi=5c414170d464452c"
                class="home-link-item link {showLinks[6]
                  ? 'link-after'
                  : ''}"
                id="playlist">🎶 Ian & Kt 4Evr 🎶</a
              >

            </li>
          </ul>
            </div>
          </div>
          <div
            class="welcome-text"
            style={screen === "large" ? "top: -170%" : ""}
          >
            <div id="welcome_date" />
          </div>
        </div>
        <div class="main-contents">
          <div class="rsvp">
            <Button text={"RSVP"} link={"/rsvp"} style={"bigbutton"} />
          </div>
          <div>
            <div class="copy">
              Come celebrate with us!
              <div
                style="letter-spacing: 0.14em; color: var(--text); font-weight: 600;"
              >
                02/17/2024
              </div>
            </div>
          </div>
          <div class="details">
            <h3>Ceremony</h3>
            <div class="time">4:00pm</div>
            <div class="location">All Souls Anglican Church</div>
            <div style="letter-spacing: 0em; margin-top: 0.5em">
              1101 Bainbridge St. <br /> Richmond, VA
            </div>
          </div>
          <div class="details">
            <h3>Reception</h3>
            <div class="location">Studio Two Three</div>
            <div style="letter-spacing: 0em; margin-top: 0.5em">
              3300 West Clay St. <br /> Richmond, VA
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {#if screen === "large"}
    <div class="sidebar" />
  {/if}
</div>
<Footer marginBottom="0px" />

<style>
  .home-wrapper {
    overflow: hidden;
    position: relative;
    display: flex;
    min-height: 100vh;
  }
  .sidebar {
    width: 45rem;
    background-color: rgb(53 21 21 / 35%);
  }
  .katie-bg {
    opacity: 0.25;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: auto;
  }
  .katie-content {
    position: relative;
    max-width: 800px;
    width: 115rem;
    flex-grow: 2;
  }
  .details {
    text-align: center;
    letter-spacing: 0.15em;
  }

  .details > h3 {
    margin-bottom: 1em;
  }

  .time {
    color: var(--text-secondary);
  }

  .location {
    color: var(--text-secondary);
  }
  .underline {
    color: var(--home-link-before);
    margin-top: -1.15em;
    border-bottom: 1px solid var(--home-link-before);
    transition:
      color 200ms linear,
      margin-top 500ms ease;
  }
  .underline-mobile {
    color: var(--home-link-before);
    margin-top: -1.25em;
    border-bottom: 1px solid var(--home-link-before);
    transition:
      color 200ms linear,
      margin-top 500ms ease;
  }
  .underline-after {
    color: transparent;
    border-bottom: 1px solid var(--home-link);
    margin-top: -0.65em;
  }
  .link {
    position: relative;
    color: var(--home-link-before);
    overflow: hidden;
    background: linear-gradient(
      to right,
      var(--home-link),
      var(--home-link) 50%,
      var(--home-link-before) 50%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 500ms;
  }
  .link-after {
    background-position: 0 100%;
  }
  .link-item {
    padding: 1rem 1rem 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-links {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
    flex-wrap: wrap;
  }
  .pic {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 65vw;
  }

  .pic-text {
    position: relative;
    top: 5%;
    display: flex;
    color: rgb(0, 0, 0);
    z-index: 2;
    font-size: 20pt;
  }

  .pic-text > div {
    width: 200%;
    text-align: center;
    letter-spacing: 0.15em;
    font-weight: 600;
    /* text-shadow: 2px 2px 10px black; */
  }

  .copy {
    padding: 0em 1em;
    font-size: 18pt;
    text-align: center;
    line-height: 1.25em;
    color: var(--text-secondary);
    text-transform: none;
    letter-spacing: 1px;
    font-weight: 300;
    font-style: normal;
  }
  #home {
    margin: 0px 0x 0px 0x;
    position: relative;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    /* background-image: url("/assets/hearts-aflame20.png"); */
  }
  #home::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .main-contents > div {
    margin-top: 3em;
  }
  .main-contents {
    z-index: 5;
    /* margin-top: 5rem;
    margin-bottom: 6rem; */
    margin-bottom: 8.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .rsvp {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .rsvp > button {
    width: 5em;
    height: 3em;
    font-size: 1.2em;
    z-index: 3;
  }
  #welcome_date {
    margin-top: -30%;
    width: 80%;
    margin-left: -10%;
  }
  .overlay {
    /* background-color: rgb(72 58 37 / 40%); */
    z-index: 1;
    position: relative;
    width: inherit;
    height: inherit;
  }
  .welcome-text > div {
    width: 200%;
  }
  .welcome-text {
    position: relative;
    top: -185%;
    left: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    z-index: 2;
  }
  #welcome-links {
    /* SWITCH /*
    /* position: absolute;
    width: 100vw;
    margin-left: 10px; */
  }
  .welcome {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* height: 74vw; */
  }

  .home-cols {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  a {
    color: var(--home-link);
  }
  a:hover {
    color: var(--home-link-hover);
    -webkit-text-fill-color: var(--home-link-hover);
    text-decoration: none;
  }
</style>
