<script>
  export let marginBottom = "100px";
</script>

<div class="spacer" style="margin-bottom: {marginBottom}">
  <footer>
    <table>
      <tbody>
        <tr class="center-container center">
          <td
            style="top: 9px; font-size: 12pt; position: relative; width: 100%;"
            color="var(--text)"
            letter-spacing="var(--letter-spacing)"
            ><a href="/" style="letter-spacing: 2px">katie & ian</a></td
          >
          <td style="top: 13px; position: relative;"
            ><img
              class="footer-image"
              src="/assets/flower-confetti-2.png"
              alt="colored martini glass red"
            /></td
          >
        </tr>
      </tbody>
    </table>
  </footer>
</div>

<style>
  .footer-image {
    width: 50px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .spacer {
    margin-bottom: 100px;
  }
  .center-container {
    margin-left: auto;
    max-width: 1240px;
    margin-right: auto;
    margin-top: -10px;
  }
  .center {
    display: block;
    position: relative;
  }

  tr {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    align-content: center;
    align-items: center;
  }
  table {
    width: 100%;
  }
  td {
    font-size: 14px;
  }
  .nav {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 15%;
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    max-width: 15%;
    min-height: 1px;
    text-align: center;
  }
  a {
    color: var(--link);
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 2px;
    margin-left: 2px;
    margin-right: 2px;
    -webkit-font-smoothing: antialiased;
  }
  a:hover {
    color: var(--link-hover);
    text-decoration: none;
  }
  @media (max-width: 570px) {
    .center {
      justify-content: space-evenly;
    }
  }
  @media (max-width: 412px) {
    .center {
      justify-content: space-evenly;
    }
  }
  @media (max-width: 381px) {
  }
</style>
