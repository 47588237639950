<script>
  import { onMount } from "svelte";
  import { theme, ianMode } from "./store.js";
  export let marginBottom = "70px";

  function colorShift() {
    document.getElementById("toggle").checked
      ? localStorage.setItem("data-theme", "dark") &&
        localStorage.setItem("ianMode", "true")
      : localStorage.setItem("data-theme", "none") &&
        localStorage.setItem("ianMode", "false");
    document.documentElement.getAttribute("data-theme") === "dark"
      ? document.documentElement.setAttribute("data-theme", "none") &&
        localStorage.setItem("ianMode", "false")
      : document.documentElement.setAttribute("data-theme", "dark") &&
        localStorage.setItem("ianMode", "true");
    localStorage.getItem("data-theme") === "dark"
      ? ($theme = "dark") && ($ianMode = "true")
      : ($theme = "none") && ($ianMode = "false");
  }
  const show = {
    activate: function () {
      document.getElementById("dropdown-menu").classList.add("show");
      this.classList.add("show");
    },
    deactivate: function () {
      [...document.getElementsByClassName("show")].forEach((el) => {
        el.classList.remove("show");
      });
    },
    activate2: function () {
      document.getElementById("dropdown-menu2").classList.add("show");
      this.classList.add("show");
    },
    deactivate2: function () {
      [...document.getElementsByClassName("show")].forEach((el) => {
        el.classList.remove("show");
      });
    },
  };
  onMount(() => {
    localStorage.getItem("data-theme") === "dark"
      ? (document.getElementById("toggle").checked = true) &&
        document.documentElement.setAttribute("data-theme", "dark")
      : (document.getElementById("toggle").checked = false) &&
        document.documentElement.setAttribute("data-theme", "none");
  });
</script>

<div class="nav-container" style="margin-bottom: {marginBottom};">
  <nav
    class="navbar navbar-color-on-scroll fixed-top navbar-expand-lg"
    color-on-scroll="100"
    id="sectionsNav"
  >
    <div class="container">
      <div class="collapse navbar-collapse">
        <a href="/">
          <img
            alt="katie icon"
            src="/images/favicon{$theme === 'dark' ? '-ian' : ''}.png"
          />
        </a>
        <div class="nav-title">
          <a href="/" id="arthurbuchanan"> katie & ian </a>
        </div>
        <ul class="navbar-nav ml-auto">
          <li />
          <li />
          <li
            class="dropdown nav-item btn btn-primary"
            on:mouseenter={show.activate}
            on:mouseleave={show.deactivate}
          >
            <a
              href="/details"
              class="dropdown-toggle nav-link btn btn-primary"
              data-toggle="dropdown"
              on:mousedown={show.activate}
              on:click={show.activate}
              id="project-drop"
            >
              <svg viewBox="0 0 50 40" width="20" height="20">
                <rect width="50" height="5" />
                <rect y="15" width="50" height="5" />
                <rect y="30" width="50" height="5" />
              </svg>
              <div class="ripple-container" />
            </a>
            <div id="dropdown-menu" class="dropdown-menu dropdown-with-icons">
              <a href="/rsvp" class="dropdown-item btn-drop btn-primary-drop"
                >rsvp</a
              >
              <a
                href="/details#events"
                class="dropdown-item btn-drop btn-primary-drop">events</a
              >
              <a
                href="/details#dress_code"
                class="dropdown-item btn-drop btn-primary-drop">dress code</a
              >
              <a
                href="/details#parking"
                class="dropdown-item btn-drop btn-primary-drop">parking</a
              >
              <a
                href="/details#accommodations"
                class="dropdown-item btn-drop btn-primary-drop"
                >accommodations</a
              >
              <a
                href="/details#registry"
                class="dropdown-item btn-drop btn-primary-drop">registry</a
              >
              <a href="/photos" class="dropdown-item btn-drop btn-primary-drop"
                >photos</a
              >
            </div>
          </li>
          <li>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div class="toggle-container" on:click={colorShift}>
              <input type="checkbox" class="toggle-input" id="toggle" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 292 142"
                class="toggle"
              >
                <path
                  d="M71 142C31.7878 142 0 110.212 0 71C0 31.7878 31.7878 0 71 0C110.212 0 119 30 146 30C173 30 182 0 221 0C260 0 292 31.7878 292 71C292 110.212 260.212 142 221 142C181.788 142 173 112 146 112C119 112 110.212 142 71 142Z"
                  class="toggle-background"
                />
                <rect
                  rx="6"
                  height="64"
                  width="12"
                  y="39"
                  x="54"
                  class="toggle-icon on"
                />
                <rect
                  rx="6"
                  height="44"
                  width="12"
                  y="-41"
                  x="90"
                  class="toggle-icon on"
                  style="rotate: 48deg;"
                />
                <rect
                  rx="6"
                  height="45"
                  width="12"
                  y="-140"
                  x="-15"
                  class="toggle-icon on"
                  style="rotate: 140deg;"
                />
                <rect
                  rx="6"
                  height="13"
                  width="13"
                  y="30"
                  x="218"
                  class="toggle-icon off"
                />
                <rect
                  rx="6"
                  height="60"
                  width="12"
                  y="55"
                  x="218"
                  class="toggle-icon off"
                />
                <g filter="url('#goo')">
                  <rect
                    fill="var(--button-background)"
                    rx="29"
                    height="58"
                    width="116"
                    y="42"
                    x="13"
                    class="toggle-circle-center"
                  />
                  <rect
                    fill="var(--button-background)"
                    rx="58"
                    height="114"
                    width="114"
                    y="14"
                    x="14"
                    class="toggle-circle left"
                  />
                  <rect
                    fill="var(--button-background)"
                    rx="58"
                    height="114"
                    width="114"
                    y="14"
                    x="164"
                    class="toggle-circle right"
                  />
                </g>
                <filter id="goo">
                  <feGaussianBlur
                    stdDeviation="10"
                    result="blur"
                    in="SourceGraphic"
                  />
                  <feColorMatrix
                    result="goo"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                    mode="matrix"
                    in="blur"
                  />
                </filter>
              </svg>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>

<style>
  @media (max-width: 950px) {
    .toggle-container {
      height: 1.7em !important;
      top: -2px;
      margin-left: 10px;
    }
  }

  .toggle-container {
    position: relative;
    aspect-ratio: 292 / 142;
    height: 1.875em;
  }

  .toggle-input {
    appearance: none;
    margin: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .toggle {
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .toggle-background {
    fill: var(--button-background-focus);
    transition: fill 0.4s;
  }

  .toggle-input:checked + .toggle .toggle-background {
    fill: var(--button-background-focus);
  }

  .toggle-circle-center {
    transform-origin: center;
    transition: transform 0.6s;
  }

  .toggle-input:checked + .toggle .toggle-circle-center {
    transform: translateX(150px);
  }

  .toggle-circle {
    transform-origin: center;
    transition: transform 0.45s;
    backface-visibility: hidden;
  }

  .toggle-circle.left {
    transform: scale(1);
  }

  .toggle-input:checked + .toggle .toggle-circle.left {
    transform: scale(0);
  }

  .toggle-circle.right {
    transform: scale(0);
  }

  .toggle-input:checked + .toggle .toggle-circle.right {
    transform: scale(1);
  }

  .toggle-icon {
    transition: fill 0.4s;
  }

  .toggle-icon.on {
    fill: var(--button-background-focus);
  }

  .toggle-input:checked + .toggle .toggle-icon.on {
    fill: #fff;
  }

  .toggle-icon.off {
    fill: #eaeaec;
  }

  .toggle-input:checked + .toggle .toggle-icon.off {
    fill: var(--button-background);
  }
  :global(
      .animation-transition-fast,
      .bootstrap-datetimepicker-widget table td > div,
      .bootstrap-datetimepicker-widget table th > div,
      .bootstrap-datetimepicker-widget table th,
      .bootstrap-datetimepicker-widget table td span,
      .navbar,
      .bootstrap-tagsinput .tag,
      .bootstrap-tagsinput [data-role="remove"],
      .card-collapse .card-header a i
    ) {
    -moz-transition: all 150ms ease 0s;
    -ms-transition: all 150ms ease 0s;
    -o-transition: all 150ms ease 0s;
    transition: all 150ms ease 0s;
    -webkit-transition: all 150ms ease 0s;
  }
  .container {
    max-width: 60rem;
  }

  .container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }

  .dropdown-menu {
    border: 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    display: none;
    opacity: 0;
    padding: 0.3125rem 0;
    transform: scale(0);
    transform-origin: 0 0;
    transition:
      transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform, opacity;
    background-clip: padding-box;
    background-color: var(--box-border);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
    color: var(--text);
    letter-spacing: var(--letter-spacing);
    display: none;
    float: left;
    font-size: 1.2rem;
    left: 0;
    list-style: none;
    margin: 0.125rem 0 0;
    min-width: 10rem;
    padding: 0.5rem 0;
    position: absolute;
    text-align: left;
    top: 100%;
    z-index: 1000;
  }

  .dropdown-menu.dropdown-with-icons .dropdown-item {
    padding: 0.75rem 1.25rem 0.75rem 0.75rem;
  }

  .dropdown-menu.dropdown-with-icons .dropdown-item {
    padding: 0.75rem 1.25rem 0.75rem 0.75rem;
  }

  .dropdown-menu .dropdown-item,
  .dropdown-menu li > a {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .dropdown-menu .dropdown-item,
  .dropdown-menu li > a {
    position: relative;
    width: auto;
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    color: var(--text);
    letter-spacing: var(--letter-spacing);
    font-weight: normal;
    text-decoration: none;
    font-size: 0.86rem;
    border-radius: 0.125rem;
    margin: 0 0.3125rem;
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    min-width: 7rem;
    padding: 0.625rem 1.25rem;
    overflow: hidden;
    line-height: 1.428571;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.625rem 1.25rem;
    clear: both;
    font-weight: 400;
    color: var(--text);
    letter-spacing: var(--letter-spacing);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }

  .dropdown-toggle:hover {
    color: var(--button-focus) !important;
  }

  :global(.dropup, .dropdown) {
    position: relative;
  }

  .fixed-top {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2;
  }

  .ml-auto {
    margin-left: auto !important;
  }

  .nav-container {
    margin-bottom: 70px;
    color: var(--text);
    letter-spacing: var(--letter-spacing);
  }

  .navbar {
    align-items: center;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    position: relative;
    border: 0;
    border-radius: 0px;
    color: var(--text);
    letter-spacing: var(--letter-spacing);
    margin-bottom: 20px;
    padding: 0.625rem 0;
    background-color: var(--background-secondary);
    /* background-color: var(--box-background); */
    width: 100% !important;
    left: 0 !important;
    position: absolute;
    height: 30px;
    max-height: 30px;
    /* box-shadow: 0px 0px 0px 1px #969696 */
  }

  .navbar .navbar-nav {
    align-items: center;
  }

  .navbar .navbar-nav .nav-item .nav-link {
    border-radius: 0px;
    color: inherit;
    font-size: 0.95rem;
    font-weight: 400;
    line-height: 20px;
    padding-top: 0.9375rem;
    padding-right: 0.9375rem;
    padding-bottom: 0.9375rem;
    padding-left: 0.9375rem;
    position: relative;
  }

  :global(.navbar > .container, .navbar > .container-fluid) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  :global(.open > .dropdown-menu, .dropdown-menu.show) {
    display: block !important;
    opacity: 1 !important;
    transform: scale(1) !important;
  }

  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }

  .navbar.fixed-top {
    border-radius: 0;
  }

  .navbar-collapse {
    align-items: center;
    flex-basis: 100%;
    flex-grow: 1;
  }

  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  :global(
      .navbar-expand-lg > .container,
      .navbar-expand-lg > .container-fluid
    ) {
    flex-wrap: nowrap;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
  }

  .nav-title {
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .nav-title > a:hover {
    color: var(--link-hover);
  }

  ul {
    margin-bottom: 1rem;
    margin-top: 0;
  }

  a {
    text-decoration: none;
    color: var(--text);
    letter-spacing: var(--letter-spacing);
  }

  #dropdown-menu {
    margin-top: -6px;
    left: auto;
    right: 0;
  }
  #dropdown-menu2 {
    margin-top: -6px;
    left: auto;
    right: 0;
  }

  .ripple-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    border-radius: inherit;
  }

  .btn.btn-primary:focus,
  .btn.btn-primary:active,
  .btn.btn-primary:hover {
    box-shadow:
      0 14px 26px -12px var(--box-shadow),
      0 4px 23px 0px rgba(0, 0, 0, 0.12),
      0 8px 10px -5px var(--box-shadow-secondary);
  }

  .btn.btn-primary:focus,
  .btn.btn-primary.focus,
  .btn.btn-primary:hover {
    color: var(--button-focus) !important;
    background-color: var(--button-background-focus);
  }
  .btn.btn-primary:hover {
    color: var(--button-focus) !important;
    background-color: var(--button-background-focus);
  }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .btn {
    text-decoration: none;
    letter-spacing: 0;
    cursor: pointer;
    background-color: inherit;
    transition:
      box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
      background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: box-shadow, transform;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    border-radius: 0px !important;
    vertical-align: middle;
    user-select: none;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  .btn-drop.btn-primary-drop:focus,
  .btn-drop.btn-primary-drop:active,
  .btn-drop.btn-primary-drop:hover {
    box-shadow:
      0 14px 26px -12px var(--box-shadow),
      0 4px 23px 0px rgba(0, 0, 0, 0.12),
      0 8px 10px -5px var(--box-shadow-secondary);
  }

  .btn-drop.btn-primary-drop:focus,
  .btn-drop.btn-primary.focus,
  .btn-drop.btn-primary-drop:hover {
    color: var(--button-focus) !important;
    background-color: var(--button-background-focus);
  }
  .btn-drop.btn-primary-drop:hover {
    color: var(--button-focus) !important;
    background-color: var(--button-background-focus);
  }
  .btn-drop:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .btn-drop {
    text-decoration: none;
    letter-spacing: 0;
    cursor: pointer;
    background-color: var(--box-border);
    transition:
      box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
      background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .btn-drop {
    will-change: box-shadow, transform;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  img {
    width: 34px;
    margin-right: 10px;
  }
  a > img {
    margin-top: 2px;
  }
  @media (max-width: 850px) {
    #project-drop {
      padding-right: 5px;
    }
    #arthurbuchanan {
      margin-right: 5px;
      margin-left: -5px;
    }
  }
  @media (max-width: 650px) {
    .navbar .navbar-nav .nav-item .nav-link {
      border-radius: 0px;
      color: var(--text);
      letter-spacing: var(--letter-spacing);
      font-size: 0.91rem;
      font-weight: 400;
      line-height: 20px;
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem;
      padding-right: 0.35rem;
      padding-left: 0.35rem;
      position: relative;
    }

    img {
      width: 35px;
      margin-right: 10px;
    }

    .container {
      margin-left: auto;
      margin-right: auto;
      padding-left: 5px;
      padding-right: 5px;
      width: 100%;
    }

    .nav-title {
      font-size: 14pt;
      line-height: 15px;
    }
  }
</style>
