<div class="modal-background">
  <div class="modal">
    <div class="loader" />
  </div>
</div>

<style>
  .loader {
    border: 16px solid var(--text);
    letter-spacing: var(--letter-spacing);
    border-top: 16px solid var(--button-background);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  .modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
</style>
