<script>
    import { functions } from "../functions"
    export let sizeClass = "";
    export let specialClass = "";
    export let containerId = "";
    export let label = "";
    export let name = "";
    export let inputId = "";
    export let required = "required";
    export let readonly = "";
    export let value = "";
    export let description = "";
    import { onMount } from "svelte";
    onMount(() => {
      functions.mountFormActions()
    })
</script>

<div class="{sizeClass}">
    <div class="form-group bmd-form-group focusable" id="{containerId}">
      <div class="bmd-label-floating-textarea"> {label} </div>
      <textarea class="form-control {specialClass} textbox" name={name}
        on:focus={functions.activate} 
        on:focus={functions.underline} 
        on:blur={functions.deactivate} 
        on:blur={functions.deunderline} 
        on:change={functions.saveInput}
      type="text" id="{inputId}" {required} {readonly} bind:value={value} />
    </div>
    <p>{description}</p>
</div>

<style>
  .textbox {
    height: 10rem;
  }
  
  p {
    color: var(--description-text);
    font-size: 12px;
    margin-top: -14px;
    text-indent: 2px;
  }
</style>