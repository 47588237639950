<script>
  import { functions } from "../functions";
  import { onMount } from "svelte";
  export let specialClass = "";
  export let sizeClass = "";
  export let containerId = "";
  export let header = "";
  export let subHeader = "";
  export let image = false;
  export let imgClass = "";
  export let screen = "portrait";
  export let imageLink = false;
  export let description = [];
  export let imgSize = 50;
</script>

<h3 id={containerId}>{header}</h3>
{#if image && screen === "portrait"}
  <img
    style="margin-bottom: -10%; margin-top: 5%;"
    class="{imgClass}img-{screen}"
    src={image}
    alt={header}
  />
{/if}
<div class={sizeClass}>
  <br />
  {#if subHeader}
    {#if specialClass === "iframe" && screen === "landscape"}
      <div class="iframe-flex">
        <div class="subheader">
          {subHeader}
        </div>
        <ul class={specialClass}>
          {#each description as bullet}
            <li>{@html bullet}</li>
          {/each}
        </ul>
      </div>
    {:else}
      <div class="subheader">
        {@html subHeader}
      </div>
    {/if}
  {/if}
  {#if image}
    <div class="{imgClass}flexbox">
      <ul class={specialClass}>
        {#each description as bullet}
          <li>{@html bullet}</li>
        {/each}
      </ul>
      {#if imageLink}
        <a
          style={screen === "portrait"
            ? "display: flex; justify-content: center;"
            : "margin-top: 1em;"}
          target="_blank"
          rel="noopener noreferrer"
          href={imageLink}
        >
          <img
            class="{imgClass}img-{screen}"
            src={image}
            alt={header}
            style="width:{imgSize}%;"
          />
        </a>
      {:else if screen === "landscape"}
        <img class="{imgClass}img-{screen}" src={image} alt={header} />
      {/if}
    </div>
  {:else if specialClass !== "iframe" || screen !== "landscape"}
    <ul class={specialClass}>
      {#each description as bullet}
        <li>{@html bullet}</li>
      {/each}
    </ul>
  {/if}
</div>

<style>
  h3 {
    text-align: center;
    letter-spacing: 0.15em;
    color: #89282f;
  }
  .iframe-flex {
    display: flex;
  }
  .flexbox {
    display: flex;
    align-items: center;
  }
  .long-flexbox {
    display: flex;
    flex-direction: column-reverse;
  }
  .long-img-portrait {
    width: 50%;
    height: 33vw;
    margin-right: auto;
    object-fit: cover;
    object-position: center;
    margin-left: auto;
    border: 2px solid var(--footer);
  }
  .img-portrait {
    width: 50%;
    margin-left: 1.4em;
    border: 2px solid var(--footer);
  }
  .long-img-landscape {
    width: 25%;
    margin-left: 5em;
    border: 2px solid var(--footer);
  }
  .img-landscape {
    width: 25%;
    margin-left: 5em;
    border: 2px solid var(--footer);
  }
  .subheader {
    font-size: 12pt;
    width: 45%;
  }
  .iframe {
    list-style-type: none;
    padding: 0vh 1.5vw;
  }
  .no-bullets {
    list-style-type: none;
    padding: 0;
  }
  .no-bullets > li {
    margin-top: 1em;
  }
  .less-indent {
    margin-left: -1.5em;
  }
  @media (max-width: 900px) {
    .subheader {
      font-size: 12pt;
      width: 100%;
    }
  }
</style>
