<script>
  import Gallery from "../components/Gallery.svelte";
  import Meta from "../components/Meta.svelte";
  import TopNav from "../components/Top-Nav.svelte";
  import Footer from "../components/Footer.svelte";
  import { onMount } from "svelte";
  import { functions } from "../functions";

  function zoom(event) {}
  $: innerWidth = 0;
  $: innerHeight = 0;
  $: screen = "large";
  $: {
    if (innerWidth >= 600) {
      screen = "large";
    } else {
      screen = "small";
    }
  }
</script>

<svelte:window bind:innerWidth bind:innerHeight />

<Meta title="Photos" />
<TopNav />
<br />
<br />
<br />
{#if innerWidth < 600}
  <Gallery
    gap={5}
    maxColumnWidth={75}
    on:click={zoom}
    hover={true}
    loading="lazy"
  >
    {#await functions.getPhotos() then photos}
      {#each photos as photo}
        <img
          src="/photos/{photo.name}{screen === 'small'
            ? '-small50'
            : ''}{photo.extension}"
          alt={photo.alt}
          class="photo"
        />
      {/each}
    {/await}
  </Gallery>
{:else}
  <Gallery
    gap={15}
    maxColumnWidth={250}
    on:click={zoom}
    hover={true}
    loading="lazy"
  >
    {#await functions.getPhotos() then photos}
      {#each photos as photo}
        <img
          src="/photos/{photo.name}{photo.extension}"
          alt={photo.alt}
          class="photo"
        />
      {/each}
    {/await}
  </Gallery>
{/if}

<Footer />

<style>
  .photo {
    opacity: 0.9;
    transition: all 0.2s;
  }
  .photo:hover {
    opacity: 1;
    transform: scale(1.04);
  }
</style>
