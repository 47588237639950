const log = console.log // log("myString")
Object.prototype.run = function() { console.log(this()); } // myFunction.run() console.logs a return statement
Object.prototype.print = function() { console.log(this.toString()); } // "myString".print()

export const functions = {

    popBlanksAndNulls: function(array) {
        let index = array.indexOf(null);
        if (index > -1) {
            array.splice(index, 1);
        }
        index = array.indexOf('');
        if (index > -1) {
            array.splice(index, 1);
        }
    },

    enforceFormat: function(event) {

        function isModifierKey(event) {
            const key = event.keyCode;
            return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            );
        };

        function isNumericInput(event) {
            const key = event.keyCode;
            return ((key >= 48 && key <= 57) || // Allow number line
            (key >= 96 && key <= 105) // Allow number pad
            );
        }

        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        if(!isNumericInput(event) && !isModifierKey(event)){
        event.preventDefault();
        };
    },

    formatToPhone: function(event) {

        function isModifierKey(event) {
            const key = event.keyCode;
            return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            );
        };

        if(isModifierKey(event)) {return;}
        const target = event.target;
        const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
        const zip = input.substring(0,3);
        const middle = input.substring(3,6);
        const last = input.substring(6,10);

        if(input.length > 6){target.value = `(${zip}) ${middle}-${last}`;}
        else if(input.length > 3){target.value = `(${zip}) ${middle}`;}
        else if(input.length > 0){target.value = `(${zip}`;};
    },

    saveInput: function() {
        localStorage.setItem(this.id, this.value);
    },

    activate: function() {
        this.parentNode.classList.add('is-focus');
    },
    underline: function() {
        this.classList.add('is-filled');
        // this.classList.remove('is-not-filled');
    },
    deunderline: function() {
        if (this.value === '') this.classList.remove('is-filled');
        if (this.value !== '') this.classList.remove('is-not-filled');
    },
    deactivate: function() {
        if (this.value === '') this.parentNode.classList.remove('is-focus'); 
    },

    showThumbnail: function() {
        document.getElementById('thumbnail-container').innerHTML = '';
        const uploadedImages = [images.files][0];
        const imgHTML = [];
        for (let i = 0; i < uploadedImages.length; i++) {
            const image = uploadedImages[i];
            imgHTML.push('<img src="'+URL.createObjectURL(image)+'" class="thumbnail"/>');
        };
        return imgHTML.forEach(img => document.getElementById('thumbnail-container').insertAdjacentHTML( 'beforeend', img));
    },

    generateUID: function() {
        let firstPart = (Math.random() * 46656) | 0;
        let secondPart = (Math.random() * 46656) | 0;
        firstPart = ("000" + firstPart.toString(36)).slice(-3);
        secondPart = ("000" + secondPart.toString(36)).slice(-3);
        return firstPart + secondPart;
    },

    displayImageCount: function() {
        document.getElementById('image-count') !== null ? document.getElementById('image-count').remove() : null;
        let fileCount = document.getElementById('thumbnail-container').children.length;
        let node = '<div id="image-count" style="display: none;">'+fileCount+' images</div>';
        return fileCount !== 0 ? document.getElementById('images').insertAdjacentHTML('afterend', node) : null;
    },

    enforceImageCount: function(n) {
        if (document.getElementById('images').files.length > n) {
            document.getElementById('images').value = null
            document.getElementById('thumbnail-container').innerHTML = '';
            document.getElementById('image-count').innerHTML = 'Upload Photos';
            document.getElementById('image-count').style.marginBottom = '25px';
            document.getElementById('enforce-image-count').style.display = 'block';
            alert(`You can add new images later. Here's your chance to modify the ${n} image(s) already uploaded.`)
            document.activeElement.blur()
        }
        else {
            document.getElementById('enforce-image-count').style.display = 'none';
        }
    },
    
    enforceImageSize: function() {
        let files = document.getElementById('photo').files
        for (var i = 0; i < files.length; i++) {
            var fileMB = ((files[i].size/1024)/1024).toFixed(2); // MB
            console.log(fileMB,"MB")
            if (fileMB > 10){
                document.getElementById('photo').value = null
                document.getElementById('thumbnail-container').innerHTML = '';
                document.getElementById('image-count').innerHTML = 'Upload Photos';
                document.getElementById('image-count').style.marginBottom = '25px';
                document.getElementById('enforce-image-size').style.display = 'block';
                alert("Please upload photos no larger than 10MB.")
                document.activeElement.blur()
            }
            else {
                document.getElementById('enforce-image-size').style.display = 'none';
            }
        }   
    },

    formatDate: function(date, char) {
        let month = date.getMonth() + 1
        return date.getFullYear() + '-' + (month.toString().length < 2 ? '0'+ month : month) + '-' + date.getDate() +
        char + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '.' + date.getMilliseconds()
    },

    mountFormActions: function() {
        function getSavedValueAsObject(v){
            const storage = Object.keys(localStorage);
            const result = {};
            for (let i = 0; i < storage.length; i++) {
                const el = storage[i];
                if (el.match(v) !== null) {
                    result[el] = localStorage.getItem(el)
                }
            }
            return result;
        };

        function getSavedValue(v){
            if (!localStorage.getItem(v)) {
                return "";
            };
            return localStorage.getItem(v);
        };

        function activateMounted(el) {
            if (el.value !== '' && el.value !== undefined) el.parentNode.classList.add('is-focus');
        };

        function underlineMounted(el) {
            if (el.value !== '' && el.value !== undefined) el.classList.add('is-filled');
        };

        function deunderlineMounted(el) {
            if (el.value === '') el.classList.remove('is-filled');
        };

        function deactivateMounted(el) {
            if (el.value === '') el.parentNode.classList.remove('is-focus'); 
        };
        
        const placeholderPosition = (el) => {
          activateMounted(el);
          deactivateMounted(el);
        };
    
        const underlinePosition = (el) => {
          underlineMounted(el);
          deunderlineMounted(el);
        }
        
        for (let i = 0; i < document.querySelectorAll('.focusable > input').length; i++) {
            const el = document.querySelectorAll('.focusable > input')[i];
            placeholderPosition(el);
        }
        
        for (let i = 0; i < document.querySelectorAll('.focusable > textarea').length; i++) {
            const el = document.querySelectorAll('.focusable > textarea')[i];
            placeholderPosition(el);
        }
        
        for (let i = 0; i < document.querySelectorAll('.form-control').length; i++) {
            const el = document.querySelectorAll('.form-control')[i];
            underlinePosition(el);
        }
    },

    getInvitees: async function () {
        const resp = await fetch(`/api/invite`);
        const data = await resp.json();
        let invitees;
        if (resp.ok) {
            invitees = data;
        } else {
            throw new Error(data);
        }
        return invitees;
    },
    getRSVPsWithPluses: async function (id, sortBy) {
        let resp = await fetch(`/api/rsvp/`);
        let data = await resp.json();
        resp =  await fetch(`/api/rsvp/-1`);
        let dataPlus = await resp.json();
        data = [...data, ...dataPlus];
        let invitees;
        if (resp.ok) {
            invitees = data;
        } else {
            throw new Error(data);
        }
        if (sortBy) {
            let result;
            switch (sortBy) {
                case 'attending':
                    result = invitees.sort((a, z) => a[sortBy] - z[sortBy]);
                    break;
                case 'datetime':
                    result = invitees.sort((a, z) => z[sortBy].localeCompare(a[sortBy]));
                    break;
                default:
                    result = invitees.sort((a, z) => a[sortBy].localeCompare(z[sortBy]));
                    break;
            }
            return result;
        }
        return invitees;
    },
    getRSVPs: async function (id, sortBy) {
        const resp = id !== undefined ? await fetch(`/api/rsvp/${id}`) : await fetch(`/api/rsvp/`) ;
        const data = await resp.json();
        let invitees;
        if (resp.ok) {
            invitees = data;
        } else {
            throw new Error(data);
        }
        if (sortBy) {
            let result;
            switch (sortBy) {
                case 'attending':
                    result = invitees.sort((a, z) => a[sortBy] - z[sortBy]);
                    break;
                case 'datetime':
                    result = invitees.sort((a, z) => z[sortBy].localeCompare(a[sortBy]));
                    break;
                default:
                    result = invitees.sort((a, z) => a[sortBy].localeCompare(z[sortBy]));
                    break;
            }
            return result;
        }
        return invitees;
    },


    getPhotos: async function () {
        const resp = await fetch(`/api/photos`);
        const data = await resp.json();
        let photos;
        if (resp.ok) {
            photos = data;
        } else {
            throw new Error(data);
        }
        return photos;
    },

    getInviteAdditions: async function (id) {
        const resp = await fetch(`/api/invite/additions/${id}`);
        const data = await resp.json();
        let additions;
        if (resp.ok) {
            additions = data;
        } else {
            throw new Error(data);
        }
        return additions;
    },

    getGuests: async function () {
        const resp = await fetch(`/api/guests`);
        const data = await resp.json();
        let guests;
        if (resp.ok) {
            guests = data;
        } else {
            throw new Error(data);
        }
        return guests;
    },

    getIngredients: async function(context) {
        if (context === '') {
            return;
        }
        let ingredients = {};
        const resp = await fetch(`/api/recipes/ingredients/${context}`);
        const data = await resp.json();
        if (resp.ok) {
            for (let i = 0; i < data.length; i++) {
                const el = data[i];
                ingredients[el.item] === undefined ?
                ingredients[el.item] = [el] :
                ingredients[el.item] = [...ingredients[el.item], el]
            };
        } else {
            throw new Error(data);
        }
        return ingredients
    },

    getDirections: async function(context) {
        if (context === '') {
            return;
        }
        let directions = {};
        const resp = await fetch(`/api/recipes/directions/${context}`);
        const data = await resp.json();
        if (resp.ok) {
            for (let i = 0; i < data.length; i++) {
                const el = data[i];
                directions[el.item] === undefined ?
                directions[el.item] = [el] :
                directions[el.item] = [...directions[el.item], el]
            };
        } else {
            throw new Error(data);
        }
        return directions;
    },

    getImages: async function(context) {
        if (context === '') {
            return;
        }
        let images = [];
        const resp = await fetch(`/api/recipes/images/${context}`);
        const data = await resp.json();
        if (resp.ok) {
            for (let i = 0; i < data.length; i++) {
                const el = data[i];
                images.push(el)
            };
        } else {
            throw new Error(data);
        }
        return images.sort((a, b) => (a.image_id > b.image_id));
    },

    getImage: async function(context, num) {
        if (context === '') {
            return;
        }
        let image = [];
        let data;
        let resp;
        if (num === 999) {
            resp = await fetch(`/api/recipes/${context}`);
            data = await resp.json();
            data = [data]
        } else {
            resp = await fetch(`/api/recipes/images/${context}/${num}`);
            data = await resp.json();
        }
        if (resp.ok) {
            const el = data[0];
            image.push(el)
        } else {
            throw new Error(data);
        }
        return image;
    },

    getCommentImages: async function(context) {
        if (context === '') {
            return;
        }
        let images = [];
        const resp = await fetch(`/api/recipes/comments/images/${context}`);
        const data = await resp.json();
        if (resp.ok) {
            for (let i = 0; i < data.length; i++) {
                const el = data[i];
                images.push(el)
            };
        } else {
            throw new Error(data);
        }
        return images.sort((a, b) => (a.image_id > b.image_id));
    },

    getUser: async function(email) {
        let user;
        const resp = await fetch(`/api/users/${email}`);
        const data = await resp.json();
        if (resp.ok) {
            user = data
        } else {
            throw new Error(data);
        }
        return user[0];
    },

    removePunctuation: function(word) {
        const punctuation = [',', '.', '?', '\'', '\`', '/', '-', '_', ':', ';', '=', '+', '#', ')', '(']
        if (word === undefined) {
            return ['', '']
        }
        if (punctuation.includes(word[word.length - 1])) {
            return [word.slice(0, word.length-1), word.slice(word.length-1, word.length)]
        };
        return [word, ''];
    },
    
    emboldenMeasurementsInDirections: function(direction) {
        const nums = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '½', '¼', '⅓', '¾','10' ,'11' ,'12' ,'13' ,'14' ,'15' ,'16' ,'17' ,'18' ,'19' ,'20' ,'21' ,'22' ,'23' ,'24' ,'25' ,'26' ,'27' ,'28' ,'29' ,'30' ,'31' ,'32' ,'33' ,'34' ,'35' ,'36' ,'37' ,'38' ,'39' ,'40' ,'41' ,'42' ,'43' ,'44' ,'45' ,'46' ,'47' ,'48' ,'49' ,'50' ,'51' ,'52' ,'53' ,'54' ,'55' ,'56' ,'57' ,'58' ,'59' ,'60' ,'61' ,'62' ,'63' ,'64' ,'65' ,'66' ,'67' ,'68' ,'69' ,'70' ,'71' ,'72' ,'73' ,'74' ,'75' ,'76' ,'77' ,'78' ,'79' ,'80' ,'81' ,'82' ,'83' ,'84' ,'85' ,'86' ,'87' ,'88' ,'89' ,'90' ,'91' ,'92' ,'93' ,'94' ,'95' ,'96' ,'97' ,'98' ,'99' ,'100'];
        const fractions = ['½', '¼', '⅓', '¾'];
        const triggers = [
                       'degrees', '°', 'degree'
                      ,'min','mins','minutes','minute'
                      ,'hrs','hr','hour','hours'
                      ,'sec', 'secs', 'seconds'
                      ,'tablespoon','tablespoons','tbsp', 'tbs', 'tbsps'
                      ,'tsp','tsps','teaspoon','teaspoons'
                      ,'c', 'cup', 'cups'
                      ,'ounce','ounces','oz','ozs'
                      ,'lb','lbs','pound','pounds'
                      ,'g', 'gram', 'grams'
                      ,'pint', 'pints'
                      ,'quart', 'quarts'
                      ,'gallon', 'gallons'
                      ,'liters', 'liter', 'milliliter', 'milliliters', 'ml', 'l', 'mls', 'ls'
                      ,'f'
                      ,'inches', 'feet', 'foot', 'inch'
                      ,'can', 'cans'
                      ,'pinch', 'pinches'
                      ,'per'
                    ]
        const emboldenedDirection = [];
        const emboldenedStep = [];
        for (let j = 0; j < direction.split(' ').length; j++) {
            const word = direction.split(' ')[j];
            const previousWord = direction.split(' ')[j - 1];
            const previousPreviousWord = direction.split(' ')[j - 2];
            if (word === '') {
                continue;
            } else if (word.includes('°') && functions.removePunctuation(word)[0] !== '°') {
                emboldenedStep.push('<b>' + functions.removePunctuation(word)[0] + '</b>' + functions.removePunctuation(word)[1])

            } else if (fractions.includes(functions.removePunctuation(word.toLowerCase())[0]) && nums.includes(previousWord[0])) {
                emboldenedStep.pop();
                emboldenedStep.push('<b>' + functions.removePunctuation(previousWord)[0] + '</b>' + functions.removePunctuation(previousWord)[1]);
                emboldenedStep.push('<b>' + functions.removePunctuation(previousWord)[0] + '</b>' + functions.removePunctuation(previousWord)[1]);

            } else if (word.includes('oz') && nums.includes(word[0])) {
                emboldenedStep.push('<b>' + functions.removePunctuation(word)[0] + '</b>' + functions.removePunctuation(word)[1])
            
            } else if (triggers.includes(functions.removePunctuation(word.toLowerCase())[0]) && nums.includes(previousWord[0])) {
                emboldenedStep.pop();
                emboldenedStep.push('<b>' + functions.removePunctuation(previousWord)[0] + ' ' + functions.removePunctuation(word.toLowerCase())[0] + '</b>' + functions.removePunctuation(word)[1]);

            } else if (triggers.includes(functions.removePunctuation(word.toLowerCase())[0]) && nums.includes(previousWord[0]) === false) {
                emboldenedStep.push('<b>' + functions.removePunctuation(word)[0] + ' ' + '</b>' + functions.removePunctuation(word)[1]);

            } else if (nums.includes(previousPreviousWord) && previousWord === 'to' && nums.includes(word)) {
                emboldenedStep.pop();
                emboldenedStep.pop();
                emboldenedStep.push(`<b>${previousPreviousWord} ${previousWord} ${word} ${functions.removePunctuation(direction.split(' ')[j+1])[0]}</b>${functions.removePunctuation(direction.split(' ')[j+1])[1]}`)
                j = j + 1;

            } else {
                emboldenedStep.push(word);

            }
        };
        return emboldenedStep.join(' ');
    },
    
    universalizeWording: function(words) {
        let revisedWords = [];
        for (let j = 0; j < words.split(' ').length; j++) {
            let word = words.split(' ')[j];
            switch (functions.removePunctuation(word.toLowerCase())[0]) {
                case 'degrees':
                    word = '°' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'min':
                    word = 'minutes' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'mins':
                    word = 'minutes' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'teaspoon':
                    word = 'tsp' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'tablespoon':
                    word = 'tbsp' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'teaspoons':
                    word = 'tsps' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'tablespoons':
                    word = 'tbsps' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'ounce':
                    word = 'oz' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'ounces':
                    word = 'oz' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'c':
                    word = 'cup' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'one':
                    word = '1' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'two':
                    word = '2' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'three':
                    word = '3' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'four':
                    word = '4' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'five':
                    word = '5' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'six':
                    word = '6' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'seven':
                    word = '7' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'eight':
                    word = '8' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case 'nine':
                    word = '9' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case '1/2':
                    word = '½' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case '1/4':
                    word = '¼' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case '1/3':
                    word = '⅓' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                case '3/4':
                    word = '¾' + functions.removePunctuation(word.toLowerCase())[1];
                    break;
                default:
                    break;
            }
            revisedWords.push(word);
        }
        return revisedWords.join(' ');
    },
};