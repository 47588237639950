<script>
  import { functions } from "../functions"
  export let sizeClass = "";
  export let containerId = "";
  export let label = "";
  export let name = "";
  export let inputId = "";
  export let required = "required";
  export let readonly = "";
  export let value = "";
  export let options = [];
  export let description = "";
  import { onMount } from "svelte";
  onMount(() => {
    functions.mountFormActions()
  })

</script>

<div class="{sizeClass}">
<div class="form-group bmd-form-group is-focus" id="{containerId}">
  <div class="title bmd-label-floating"> {label} </div>
  <div class="flex-container">
    {#each options as option}        
      <div class="radio-option">
        <label for="{inputId}">
          <input class="lessen form-control" name={name}
            on:change={functions.saveInput}
            type="radio" id="{inputId}" {required} {readonly} bind:group={value} value={Object.values(option)[0]}/> {Object.keys(option)[0]}
        </label>
      </div>
    {/each}
  </div>
</div>
<p>{description}</p>
</div>

<style>
.flex-container {
  display: flex;
  margin-top: 1.5rem;
  justify-content: space-around;
}
.radio-option {
  display: flex;
  flex-direction: row;
}
.radio-option > label {
  padding-right: 0.35rem;
}
.lessen {
  width: auto;
  height: auto;
  margin: auto auto 5px auto;
  padding: 0;
}
p {
  color: var(--description-text);
  font-size: 12px;
  margin-top: -14px;
  text-indent: 2px;
}
</style>