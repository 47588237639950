<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { tick } from "svelte";

  export let gap = 10;
  export let maxColumnWidth = 250;
  export let hover = false;
  export let loading;

  const dispatch = createEventDispatcher();

  let slotHolder = null;
  let columns = [];
  let galleryWidth = 0;
  let columnCount = 0;
  let modal = false;

  $: columnCount = parseInt(galleryWidth / maxColumnWidth) || 1;
  $: columnCount && Draw();
  $: galleryStyle = `grid-template-columns: repeat(${columnCount}, 1fr); --gap: ${gap}px`;

  onMount(Draw);

  async function unZoom(event) {
    const gallery = document.getElementById("gallery");
    const zoomer = document.querySelector(".zoom");
    if (modal && zoomer) {
      if (Array.from(event.target.classList).includes("zoom")) {
        return;
      }
      zoomer.classList.remove("zoom");
      gallery.style.setProperty("--fade", "transparent");
      document.querySelector(".x").remove();
      document.querySelector(".left").remove();
      document.querySelector(".left").remove();
      document.querySelector(".right").remove();
      document.querySelector(".right").remove();
      document.body.style["overflow-y"] = "scroll";
      await new Promise((resolve) => setTimeout(resolve, 200));
      gallery.classList.remove("fade");
      zoomer.classList.add("photo");
      zoomer.classList.add("img-hover");
      modal = false;
    }
  }
  async function cycleNextOrPrevious(direction) {
    if (direction === "left") {
      document.querySelector(".zoom");
      unZoom();
    }
  }
  async function zoom(event) {
    if (Array.from(event.target.classList).includes("zoom")) {
      return;
    }
    await new Promise((resolve) => setTimeout(resolve, 20));
    const gallery = document.getElementById("gallery");
    gallery.style.setProperty("--fade", "rgb(30 30 30 / 90%)");
    document.body.style["overflow-y"] = "hidden";
    modal = true;
    document.getElementById("gallery").classList.add("fade");
    let x = document.createElement("div");
    let left = document.createElement("div");
    let right = document.createElement("div");
    left.innerText = "<";
    right.innerText = ">";
    x.classList.add("x");
    left.classList.add("left");
    right.classList.add("right");
    dispatch(
      "click",
      event.target.classList.remove("photo"),
      event.target.classList.remove("img-hover"),
      event.target.classList.add("zoom"),
      event.target.insertAdjacentElement("beforebegin", x),
      event.target.insertAdjacentElement("beforebegin", left),
      event.target.insertAdjacentElement("beforebegin", right)
    );
  }

  async function Draw() {
    await tick();

    if (!slotHolder) {
      return;
    }

    const images = Array.from(slotHolder.childNodes).filter(
      (child) => child.tagName === "IMG"
    );
    columns = [];

    // Fill the columns with image URLs
    for (let i = 0; i < images.length; i++) {
      const idx = i % columnCount;
      columns[idx] = [
        ...(columns[idx] || []),
        { src: images[i].src, alt: images[i].alt, class: images[i].className },
      ];
    }
  }
</script>

<svelte:body on:click={unZoom} />
<div
  id="slotHolder"
  bind:this={slotHolder}
  on:DOMNodeInserted={Draw}
  on:DOMNodeRemoved={Draw}
>
  <slot />
</div>

{#if columns}
  <div id="gallery" bind:clientWidth={galleryWidth} style={galleryStyle}>
    {#each columns as column}
      <div class="column">
        {#each column as img}
          <img
            src={img.src}
            alt={img.alt}
            on:click={zoom}
            class="{hover === true ? 'img-hover' : ''} {img.class}"
            {loading}
          />
        {/each}
      </div>
    {/each}
  </div>
{/if}

<style>
  :global(.left) {
    position: fixed;
    left: 10%;
    top: 50%;
    width: 20px;
    height: 20px;
    opacity: 0.75;
    cursor: pointer;
    font-size: 5em;
    color: white;
    z-index: 10;
  }
  :global(.right) {
    position: fixed;
    right: 10%;
    top: 50%;
    width: 20px;
    height: 20px;
    opacity: 0.75;
    cursor: pointer;
    font-size: 5em;
    color: white;
    z-index: 10;
  }
  @media (max-width: 900px) {
    :global(.left) {
      position: fixed;
      left: 10%;
      top: 91%;
      width: 20px;
      height: 20px;
      opacity: 0.75;
      cursor: pointer;
      font-size: 3em;
      color: white;
      z-index: 10;
    }
    :global(.right) {
      position: fixed;
      right: 10%;
      top: 91%;
      width: 20px;
      height: 20px;
      opacity: 0.75;
      cursor: pointer;
      font-size: 3em;
      color: white;
      z-index: 10;
    }
  }
  #slotHolder {
    display: none;
  }
  #gallery {
    width: 100%;
    display: grid;
    gap: var(--gap);
  }
  #gallery::before {
    transition: all 0.2s;
  }
  #gallery .column {
    display: flex;
    flex-direction: column;
  }
  #gallery .column * {
    width: 100%;
    margin-top: var(--gap);
  }
  #gallery .column *:nth-child(1) {
    margin-top: 0;
  }
  :global(.fade::before) {
    animation: fadeIn 0.01s;
    -webkit-animation: fadeIn 0.01s;
    -moz-animation: fadeIn 0.01s;
    -o-animation: fadeIn 0.01s;
    -ms-animation: fadeIn 0.01s;
    background-color: var(--fade);
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    min-width: 100%;
    min-height: 100vh;
    z-index: 8;
    background-size: auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .img-hover {
    opacity: 0.9;
    transition: all 0.2s;
    cursor: pointer;
  }
  .img-hover:hover {
    opacity: 1;
    transform: scale(1.05);
    cursor: pointer;
  }
  .zoom {
    display: block;
    border: var(--input-border-focus) 1px solid;
    position: fixed;
    margin-top: auto !important;
    width: auto !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 75%;
    opacity: 1;
    box-shadow: 0 10px 20px rgb(0 0 0 / 50%);
    z-index: 9;
    max-height: 90%;
  }
  @media (max-width: 900px) {
    .zoom {
      max-width: 95%;
    }
    .img-hover {
      opacity: 0.99;
    }
  }
</style>
