<script>
  export let text;
  export let link;
  export let style;
</script>

{#if link}
  <a href={link}>
    <button class={`btn btn-primary ${style}`}>
      {text}
    </button>
  </a>
{:else}
  <button class={`btn btn-primary ${style}`}>
    {text}
  </button>
{/if}

<style>
  .bigbutton {
    width: 5em;
    height: 3em;
    font-size: 1.2em;
    z-index: 3;
  }
  @media (max-width: 950px) {
    .bigbutton {
      width: 4.5em !important;
      height: 2.4em !important;
      font-size: 1.1em !important;
    }
  }
  a {
    color: var(--text);
    text-decoration: none;
  }
</style>
